// 分拣
import Vue from 'vue';
import { $api } from '@/http';

const getDefaultState = (sortType = 1) => ({
  sortDetail: {
    brandName: ``,
    productName: ``,
    modelName: ``,
    total: 0,
  },
  // 按SN分拣
  sortInSn: {
    // 理想的数据结构
    // 'LISIAGNET-1': {
    //   boxNo: 'LISIAGNET-1',
    //   total: 0,
    //   snList: [
    //     {
    //       boxNo: 'LISIAGNET-1',
    //       id: '1483765953660575745',
    //       sn: '0068AG001'
    //     }
    //   ]
    // }
  },
  // 按箱分拣
  sortInBox: {},
  // 1 终端分拣，2 调拨分拣
  sortType,
  // 终端分拣api
  api1: {
    device_choose_order_info: $api.terminalOrder.device_choose_order_info,
    device_choose_order_choose_finish_list:
      $api.terminalOrder.device_choose_order_choose_finish_list,
    device_choose_order_choose_commit:
      $api.terminalOrder.device_choose_order_choose_commit,
    device_choose_order_choose_box_list:
      $api.terminalOrder.device_choose_order_choose_box_list,
    device_choose_order_choose_sn_list:
      $api.terminalOrder.device_choose_order_choose_sn_list,
  },
  // 调拨分拣api
  api2: {
    device_choose_order_info: $api.transfer.device_choose_info,
    device_choose_order_choose_finish_list:
      $api.transfer.device_choose_finish_list,
    device_choose_order_choose_commit: $api.transfer.device_choose_commit,
    device_choose_order_choose_box_list: $api.transfer.device_choose_box_list,
    device_choose_order_choose_sn_list: $api.transfer.device_choose_sn_list,
  },
});

const state = getDefaultState();

const getters = {
  sortApi: state => (state.sortType === 1 ? state.api1 : state.api2),

  // 按SN分拣的某个箱号选中的sn集合
  snSelectionInSn: state => boxNo =>
    (state.sortInSn[boxNo]?.snList || []).map(el => el.sn),

  // 所有按SN分拣的箱号已分拣的sn对象列表
  snSelectionObjInSn: state => {
    return Object.values(state.sortInSn).reduce(
      (prev, curr) => prev.concat(curr.snList || []),
      []
    );
  },

  // 数组形式的sortInBox
  sortInBoxArr: state => Object.values(state.sortInBox),

  // 按箱分拣的选中的boxNo集合
  boxSelectionInBox: (state, getters) =>
    getters.sortInBoxArr.filter(el => el.snList?.length).map(el => el.boxNo),

  // 按箱分拣的所有sn数量
  snSelectionObjInBox: (state, getters) =>
    getters.sortInBoxArr.reduce(
      (prev, curr) => prev.concat(curr.snList || []),
      []
    ),

  // 所有已选中的sn和箱号的总sn数量
  selectedLength: (state, getters) =>
    getters.snSelectionObjInSn.length + getters.snSelectionObjInBox.length,

  // 还需分拣
  needSort: (state, getters) =>
    Math.max(state.sortDetail.total - getters.selectedLength, 0) || 0,
};

const mutations = {
  SET_SORT_TYPE: (state, sortType) => {
    state.sortType = sortType;
  },
  RESET_STATE: (state, sortType) => {
    Object.assign(state, getDefaultState(sortType));
  },
  SET_SORT_DETAIL: (state, detail) => {
    state.sortDetail = detail;
  },
  /**
   * 按SN分拣 -- 设置箱子
   * @param {Object} box 箱子对象
   */
  SET_BOX_IN_SN: (state, { boxNo, value }) => {
    Vue.set(state.sortInSn, boxNo, {
      snList: [],
      ...value,
    });
    // state.sortInSn[boxNo] = value
  },
  // 设置某个箱子的sn列表
  SET_SN_LIST_IN_SN: (state, sn) => {
    Vue.set(state.sortInSn[sn.boxNo], `snList`, sn.snList);
    if (sn.snList.length == 0) {
      Reflect.deleteProperty(state.sortInSn, sn.boxNo);
    }
  },
  // 添加sn
  ADD_SN_IN_SN: (state, sn) => {
    state.sortInSn[sn.boxNo].snList.push(sn);
  },
  // 移除sn
  REMOVE_SN_IN_SN: (state, sn) => {
    const snList = state.sortInSn[sn.boxNo].snList;
    const index = snList.findIndex(el => el.id === sn.id);
    snList.splice(index, 1);
    if (snList.length == 0) {
      Reflect.deleteProperty(state.sortInSn, sn.boxNo);
    }
  },
  CLEAR_IN_SN: state => (state.sortInSn = {}),

  //设置整个已选
  SET_IN_SN: (state, data) => {
    state.sortInSn = data;
  },

  SET_BOX_IN_BOX: (state, box) => {
    Vue.set(state.sortInBox, box.boxNo, {
      snList: [],
      ...box,
    });
  },
  // 移除某个箱子的单个sn
  REMOVE_SN_IN_BOX: (state, sn) => {
    const snList = state.sortInBox[sn.boxNo].snList;
    const index = snList.findIndex(el => el.id === sn.id);
    snList.splice(index, 1);
    if (snList.length == 0) {
      Reflect.deleteProperty(state.sortInSn, sn.boxNo);
    }
  },
  // 设置某个箱子的sn列表
  SET_SN_LIST_IN_BOX: (state, sn) => {
    Vue.set(state.sortInBox[sn.boxNo], `snList`, sn.snList);
    if (sn.snList.length == 0) {
      Reflect.deleteProperty(state.sortInBox, sn.boxNo);
    }
  },
  CLEAR_IN_BOX: state => (state.sortInBox = {}),

  SET_IN_BOX: (state, data) => (state.sortInBox = data),
};

const actions = {
  // 设置某个箱号的整个sn列表
  setSnListInSn: ({ commit }, { box, snList }) => {
    box = JSON.parse(JSON.stringify(box));
    snList = [...snList];

    if (!state.sortInSn[box.boxNo]) {
      commit(`SET_BOX_IN_SN`, {
        boxNo: box.boxNo,
        value: box,
      });
    }

    commit(`SET_SN_LIST_IN_SN`, {
      boxNo: box.boxNo,
      snList,
    });
  },
  // 设置sn
  setSnInSn: ({ commit, state }, { box, sn, removeWhenHave = true }) => {
    box = JSON.parse(JSON.stringify(box));

    const curr = state.sortInSn[box.boxNo];

    if (!curr) {
      commit(`SET_BOX_IN_SN`, {
        boxNo: box.boxNo,
        value: box,
      });
      commit(`SET_SN_LIST_IN_SN`, {
        boxNo: box.boxNo,
        snList: [sn],
      });
    } else {
      // 判断是否已经选中了sn
      if (curr.snList.some(el => el.id === sn.id)) {
        // 根据removeWhenHave的值判断是否删除
        if (removeWhenHave) {
          commit(`REMOVE_SN_IN_SN`, sn);
        }
      } else {
        commit(`ADD_SN_IN_SN`, sn);
      }
    }
  },

  // 设置某个箱号的整个sn列表
  setSnListInBox: ({ commit }, { box, snList }) => {
    box = JSON.parse(JSON.stringify(box));
    snList = [...snList];

    if (!state.sortInBox[box.boxNo]) {
      commit(`SET_BOX_IN_BOX`, box);
    }

    commit(`SET_SN_LIST_IN_BOX`, {
      boxNo: box.boxNo,
      snList,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
