export default [
  {
    path: '/verified',
    name: '实名认证',
    redirect: '/verified/attention',
    component: {
      name: 'verifiedAttention',
      render() {
        return <keep-alive include={this.$store.state.viewsCache.caches}>
          <router-view />
        </keep-alive>;
      },
    },
    children: [
      {
        path: 'attention',
        name: 'attention',
        component: () => import('@/views/verified/attention'),
      },
      {
        path: 'success',
        component: () => import('@/views/verified/success'),
      },
      {
        path: 'idcard',
        name: 'idcard',
        component: () => import('@/views/verified/idcard'),
      },
      {
        path: 'bankcard',
        name: 'bankcard',
        component: () => import('@/views/verified/bankcard'),
      },
      {
        path: 'banklist',
        name: 'banklist',
        component: () => import('@/views/verified/banklist'),
      },
      {
        path: 'uploadIdCard',
        name: 'uploadIdCard',
        component: () => import('@/views/verified/uploadIdCard'),
      },
      {
        path: 'uploadBankCard',
        name: 'uploadBankCard',
        component: () => import('@/views/verified/uploadBankCard'),
      },
    ],
  }
];
