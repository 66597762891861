import request from '../request';
import qs from 'qs';


// 展业中心-名片设置-查询
export function operate_poster_card_info(params) {
  return request({
    url: `/operate/poster/card/get`,
    method: `get`,
    params,
  });
}

// 展业中心-名片设置-查询
export function operate_poster_card_save(data) {
  return request({
    url: `/operate/poster/card/save`,
    method: `post`,
    data,
  });
}

// 邀请好友
export function operate_poster_apply(params = {}) {
  return request({
    url: `/operate/poster/poster/apply`,
    method: `get`,
    params,
  });
}

// 展业中心-热门推荐
export function operate_poster_hot_page(params, showLoading) {
  return request({
    url: `/operate/poster/poster/hot/page`,
    method: `get`,
    params,
    showLoading,
  });
}

// 展业中心-热门海报6张
export function operate_poster_list(params, showLoading) {
  return request({
    url: `/operate/poster/poster/list`,
    method: `get`,
    params,
    showLoading,
  });
}

// 展业中心-热门海报-更多-海报中心
export function operate_poster_page(params, showLoading) {
  return request({
    url: `/operate/poster/poster/page`,
    method: `get`,
    params,
    showLoading,
  });
}

// 展业中心-热门海报-分享
export function operate_poster_share(data) {
  return request({
    headers: {
      'Content-Type': `application/x-www-form-urlencoded`,
    },
    url: `/operate/poster/poster/share`,
    method: `post`,
    data: qs.stringify(data),
  });
}
// 展业中心-交易-交易总额-数据图表
export function data_trans_stat_chart(params, showLoading) {
  return request({
    url: `/data/trans/stat/chart`,
    method: `get`,
    params,
    showLoading
  });
}

// 展业中心-交易-交易头部数据
export function data_trans_new_add(params, showLoading) {
  return request({
    url: `/data/trans/new/add`,
    method: `get`,
    params,
    showLoading,
  });
}

// 展业中心-交易-交易商户数-数据图表
export function data_trans_merchant_chart(params, showLoading) {
  return request({
    url: `/data/trans/merchant/chart`,
    method: `get`,
    params,
    showLoading
  });
}

// 展业中心-交易-交易户均数-数据图表
export function data_trans_avg_chart(params, showLoading) {
  return request({
    url: `/data/trans/avg/chart`,
    method: `get`,
    params,
    showLoading
  });
}

// 展业中心-交易-直营交易查询列表
export function data_trans_stat_list(params) {
  return request({
    url: `/data/trans/stat/list`,
    method: `get`,
    params,
  });
}

// 展业中心-代理统计-数据图表
export function data_agent_chart(params) {
  return request({
    url: `/data/agent/chart`,
    method: `get`,
    params,
  });
}

// 展业中心-代理统计-代理查询
export function data_agent_stat_descendant_list(params) {
  return request({
    url: `/data/agent/stat/descendant/list`,
    method: `get`,
    params,
  });
}

// 展业中心-代理统计-代理查询汇总数据
export function data_agent_stat_descendant_summary(params) {
  return request({
    url: `/data/agent/stat/descendant/summary`,
    method: `get`,
    params,
  });
}

// 展业中心-代理统计-交易-列表-详情-交易统计-按日-按月分页查询
export function data_agent_stat_descendant_one_list(params) {
  return request({
    url: `/data/agent/stat/descendant/one/list`,
    method: `get`,
    params,
  });
}

// 展业中心-代理统计-代理交易前十查询
export function data_agent_stat_top_list(params) {
  return request({
    url: `/data/agent/stat/top/list`,
    method: `get`,
    params,
  });
}

// 展业中心-交易-直营交易查询列表-汇总金额
export function data_trans_stat_total(params) {
  return request({
    url: `/data/trans/stat/total`,
    method: `get`,
    params,
  });
}

// 展业中心-交易-直营交易查询列表-交易查询详情
export function data_trans_stat_tran_detail(params) {
  return request({
    url: `/data/trans/stat/tran/detail`,
    method: `get`,
    params,
  });
}

// 展业中心-激活统计-新增情况
export function data_achieve_new_add(params) {
  return request({
    url: `/data/achieve/new/add`,
    method: `get`,
    params,
  });
}

// 展业中心-激活统计-柱状图
export function data_achieve_chart(params) {
  return request({
    url: `/data/achieve/chart`,
    method: `get`,
    params,
  });
}

// 展业中心-激活统计-分布
export function data_achieve_dist(params) {
  return request({
    url: `/data/achieve/dist`,
    method: `get`,
    params,
  });
}

// 展业中心-商户统计-新增情况
export function data_merchant_new_add(params) {
  return request({
    url: `/data/merchant/new/add`,
    method: `get`,
    params,
  });
}

// 展业中心-商户统计-柱状图
export function data_merchant_chart(params) {
  return request({
    url: `/data/merchant/chart`,
    method: `get`,
    params,
  });
}

// 展业中心-商户统计-分布
export function data_merchant_dist(params) {
  return request({
    url: `/data/merchant/dist`,
    method: `get`,
    params,
  });
}

// 展业中心-商户管理-分页
export function data_merchant_page(params) {
  return request({
    url: `/data/merchant/page`,
    method: `get`,
    params,
  });
}

// 代理统计-交易-列表-详情-交易统计
export function data_agent_stat_descendant_one_info(params) {
  return request({
    url: `/data/agent/stat/descendant/one/info`,
    method: `get`,
    params,
  });
}

// 代理查询-激活统计-趋势图
export function data_achieve_agent_trend(agentId) {
  return request({
    url: `/data/achieve/agent/trend/${agentId}`,
    method: `get`
  });
}

// 代理查询-激活统计-团队新增
export function data_achieve_agent_statis(params) {
  return request({
    url: `/data/achieve/agent/statis`,
    method: `get`,
    params
  });
}

// APP-代理查询-激活统计-详情
export function data_achieve_agent_statis_detail(params) {
  return request({
    url: `/data/achieve/agent/statis/detail`,
    method: `get`,
    params
  });
}

// 展业中心-代理查询-交易详情-按日按月排序-详情
export function data_agent_stat_descendant_info_list(params) {
  return request({
    url: `/data/agent/stat/descendant/info/list`,
    method: `get`,
    params
  });
}

// app-商户-商户详情-交易总交易额
export function data_trans_merchant_amount(params) {
  return request({
    url: `/data/trans/merchant/amount`,
    method: `get`,
    params
  });
}

// app-商户-商户详情-商户按月统计数据
export function data_trans_merchant_month_list(params) {
  return request({
    url: `/data/trans/merchant/month/list`,
    method: `get`,
    params
  });
}

// 商户管理-商户终端信息-分页
export function data_merchant_device_page(params) {
  return request({
    url: `/data/merchant/device/page`,
    method: `get`,
    params
  });
}
