import request from '../request';

// 消息分类
export function message_group(showLoading) {
  return request({
    url: `/operate/message/group`,
    method: `get`,
    showLoading,
  });
}

// 消息列表
export function message_page(params) {
  return request({
    url: `/operate/message/page`,
    method: `get`,
    params
  });
}

// 删除消息
export function message_delete(data) {
  return request({
    url: `/operate/message/delete/${data.id}`,
    method: `post`,
  });
}


// 删除详情
export function message_detail(params) {
  return request({
    url: `/operate/message/${params.id}`,
    method: `get`,
    params
  });
}

// 消息已读
export function message_read(data, showLoading) {
  return request({
    url: `/operate/message/read`,
    method: `post`,
    data,
    showLoading,
  });
}

// 获取消息开关设置
export function agent_operate_message_open_list(params) {
  return request({
    url: `/operate/message/open/list`,
    method: `get`,
    params
  });
}

// 设置开关
export function agent_operate_message_open_set(data, showLoading) {
  return request({
    url: `/operate/message/open/set`,
    method: `post`,
    data,
    showLoading,
  });
}


