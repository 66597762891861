import { storage } from '@/utils/storage';
import { $api } from '@/http';

const state = {
  dicts: storage.getItem(`dicts`) || {}
};

const mutations = {
  SET_DICTS: (state, dicts) => {
    state.dicts = dicts;
  }
};

const actions = {
  async getDict({ commit, state }, type) {
    // 通过codeType，获取对应字典，进行缓存
    const dictsObj = JSON.parse(JSON.stringify(storage.getItem(`dicts`) || {}));
    // 是否存在当前type字典
    const isExist = !!dictsObj[type];
    // console.log(dictsObj,state.dicts,type);
    if (isExist) return dictsObj[type];

    const res = await $api.common.common_dict(type);
    const { data } = res.data;
   console.log(data,'data')
    dictsObj[type] = data;
    storage.setItem(`dicts`, dictsObj);
    commit(`SET_DICTS`, dictsObj);

    return data;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

