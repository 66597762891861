import request from '../request';

// 办卡-银行中心
export function bank_center(params) {
  return request({
    url: '/va/bank/center',
    method: 'get',
    params,
  });
}

// 贷款- 产品中心
export function loan_center(params) {
  return request({
    url: `/va/loan/center`,
    method: `get`,
    params,
  });
}

// 贷款- 佣金说明
export function loan_commission(params) {
  return request({
    url: `/va/loan/commission`,
    method: `get`,
    params,
  });
}

// 办卡- 佣金说明
export function bank_commission(params) {
  return request({
    url: `/va/bank/commission`,
    method: `get`,
    params,
  });
}

// 贷款订单-直营-分页
export function loan_order_direct_page(params) {
  return request({
    url: `/va/loan/order/direct/page`,
    method: `get`,
    params,
  });
}

// 贷款订单-直营-表头
export function loan_order_direct_title(params) {
  return request({
    url: `/va/loan/order/direct/title`,
    method: `get`,
    params,
  });
}

// 发卡订单-直营-表头
export function card_order_direct_title(params) {
  return request({
    url: `/va/card/order/direct/title`,
    method: `get`,
    params,
  });
}

// 办卡订单-直营-分页
export function card_order_direct_page(params) {
  return request({
    url: `/va/card/order/direct/page`,
    method: `get`,
    params,
  });
}

// 申卡进度查询
export function card_bank_progress(params) {
  return request({
    url: `/va/bank/progress`,
    method: `get`,
    params,
  });
}

// 银行详情
export function bank_detail(id) {
  return request({
    url: `/va/bank/detail/${id}`,
    method: 'get',
  });
}

// 贷款产品详情
export function loan_detail(id) {
  return request({
    url: `/va/loan/detail/${id}`,
    method: 'get',
  });
}

// app内启动广告-轮播图查询
export function operate_advertisement_list(params) {
  return request({
    url: `/operate/advertisement/list`,
    method: `get`,
    params,
  });
}

// 贷款订单-创建
export function loan_order_create(data) {
  return request({
    url: `/va/loan/order/create`,
    method: `post`,
    data,
  });
}

// 办卡订单-创建
export function card_order_create(data) {
  return request({
    url: `/va/card/order/create`,
    method: `post`,
    data,
  });
}

// 海报
export function poster_list(params) {
  return request({
    url: `/va/poster/list`,
    method: `get`,
    params,
  });
}

// 开始抽奖
export function post_draw_do(data) {
  return request({
    url: `/va/lucky/draw/do`,
    method: `post`,
    data,
  });
}

// 我的奖品
export function post_my_prize(params) {
  return request({
    url: `/va/lucky/draw/my/prize`,
    method: `get`,
    params,
  });
}

// 中奖名单
export function post_draw_roll(params) {
  return request({
    url: `/va/lucky/draw/roll`,
    method: `get`,
    params,
  });
}

// 活动内容
export function post_draw_valid(params) {
  return request({
    url: `/va/lucky/draw/valid`,
    method: `get`,
    params,
  });
}

// 活动开启状态查询
export function get_draw_exist(params, productType) {
  return request({
    url: `/va/lucky/draw/exist/${productType}`,
    method: `get`,
    params,
  });
}
// 等级说明
export function member_level_desc(params) {
  return request({
    url: '/va/member/level/desc',
    method: 'get',
    params,
  });
}

// 初始化会员
export function member_init_member(params) {
  return request({
    url: '/va/member/init/member',
    method: 'get',
    params,
  });
}

// 个人业绩
export function member_achieve(params) {
  return request({
    url: '/va/member/achieve',
    method: 'get',
    params,
  });
}

// 增值排行榜
export function member_rank(yyyyMM) {
  return request({
    url: `/va/member/rank/${yyyyMM}`,
    method: 'get',
  });
}

// 增值-办卡量奖励活动入口
export function activity_card_activity_entrance(params) {
  return request({
    url: `/va/activity/card/activity/entrance`,
    method: 'get',
    params,
  });
}

// 增值-办卡量奖励活动进度
export function activity_card_activity_progress(params) {
  return request({
    url: `/va/activity/card/activity/progress`,
    method: 'get',
    params,
  });
}

// 办卡量活动-银行列表
export function activity_app_card_activity_bank(params) {
  return request({
    url: `/va/activity/app/card/activity/bank`,
    method: 'get',
    params,
  });
}

// 增值-办卡量活动领取优惠券
export function activity_card_activity_coupon_receive(data) {
  return request({
    url: `/va/activity/card/activity/coupon/receive`,
    method: `post`,
    data,
  });
}

// 办卡量活动-银行列表
export function activity_app_card_activity_reward_page(params) {
  return request({
    url: `/va/activity/app/card/activity/reward/page`,
    method: 'get',
    params,
  });
}

// 直营业绩
export function performance_direct(params) {
  return request({
    url: `/va/performance/direct`,
    method: 'get',
    params,
  });
}

// 直推代理列表
export function performance_direct_agent(params) {
  return request({
    url: `/va/performance/direct/agent`,
    method: 'get',
    params,
  });
}

// 团队业绩
export function performance_team(params) {
  return request({
    url: `/va/performance/team`,
    method: 'get',
    params,
  });
}

// 黑名单
export function bankList_get(params) {
  return request({
    url: `/va/bank/get`,
    method: 'get',
    params,
  });
}

//----------------------------------------zy02-----------------------------------------
// 银行中心
export function zy_card_center(params) {
  return request({
    url: `/zy/card/center`,
    method: 'get',
    params,
  });
}

// 佣金说明
export function zy_card_commission(params) {
  return request({
    url: `/zy/card/commission`,
    method: 'get',
    params,
  });
}

// 卡片列表
export function zy_card_list(params) {
  return request({
    url: `/zy/card/list`,
    method: 'get',
    params,
  });
}

// 申卡进度查询
export function zy_card_progress(params) {
  return request({
    url: `/zy/card/progress`,
    method: 'get',
    params,
  });
}

//--------------------贷款-----------

// 贷款中心
export function zy_loan_app_center(params) {
  return request({
    url: `/zy/loan/app/center`,
    method: 'get',
    params,
  });
}

// 贷款详情
export function zy_loan_app_detail(id) {
  return request({
    url: `/zy/loan/app/detail/${id}`,
    method: 'get',
  });
}

// 佣金说明
export function zy_loan_commission(params) {
  return request({
    url: `/zy/loan/commission`,
    method: 'get',
    params,
  });
}

//--------------------活动-----------
// 分页
export function va_activity_app_center(params) {
  return request({
    url: `/va/activity/app/center`,
    method: 'get',
    params,
  });
}

// 推广活动详情
export function va_activity_promote_detail(id) {
  return request({
    url: `/va/activity/promote/detail/${id}`,
    method: 'get',
  });
}

// 新人首单礼活动详情
export function va_activity_first_order_detail(id) {
  return request({
    url: `/va/activity/first/order/detail/${id}`,
    method: 'get',
  });
}


//营销活动奖励统计
export function va_activity_app_reward_statistics(params) {
  return request({
    url: `/va/activity/app/reward/statistics`,
    method: 'get',
    params,
  });
}

//营销活动奖励统计
export function va_activity_app_reward_page(params) {
  return request({
    url: `/va/activity/app/reward/page`,
    method: 'get',
    params,
  });
}

// 奖励详情
export function va_activity_app_reward_detail(id) {
  return request({
    url: `/va/activity/app/reward/detail/${id}`,
    method: 'get',
  });
}

//-------------------------办卡订单-------------------
// 办卡订单-表头
export function card_order_title(params) {
  return request({
    url: `/va/card/order/title`,
    method: `get`,
    params,
  });
}

// 办卡订单-分页
export function card_order_page(params) {
  return request({
    url: `/va/card/order/page`,
    method: `get`,
    params,
  });
}

// 办卡订单-详情
export function card_order_detail(id) {
  return request({
    url: `/va/card/order/detail/${id}`,
    method: `get`,
  });
}

// 办卡订单-申请V2
export function card_order_createV2(data) {
  return request({
    url: `/va/card/order/createV2`,
    method: `post`,
    data
  });
}

// 申卡进度查询
export function zy_card_bank_progress(params) {
  return request({
    url: `/zy/card/progress`,
    method: `get`,
    params,
  });
}

//-------------------------贷款订单-------------------
// 贷款订单-表头
export function loan_order_title(params) {
  return request({
    url: `/va/loan/order/title`,
    method: `get`,
    params,
  });
}

// 贷款订单-分页
export function loan_order_page(params) {
  return request({
    url: `/va/loan/order/page`,
    method: `get`,
    params,
  });
}

// 贷款订单-详情
export function loan_order_detail(id) {
  return request({
    url: `/va/loan/order/detail/${id}`,
    method: `get`,
  });
}

// 贷款订单-申请V2
export function loan_order_createV2(data) {
  return request({
    url: `/va/loan/order/createV2`,
    method: `post`,
    data
  });
}

export function activity_app_card_activity_bankV2(params) {
  return request({
    url: `/va/activity/card/activity/bank/list`,
    method: 'get',
    params,
  });
}

export function activity_app_card_activity_card_list(params) {
  return request({
    url: `/va/activity/card/activity/card/list`,
    method: 'get',
    params,
  });
}