const state = {
  selectedDeopt: {
    id: '',
    name: ''
  }
}

const mutations = {
  SET_SELECTED_DEPOT: (state, depot) => {
    state.selectedDeopt = depot
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
