export default [
  {
    path: '/login',
    name: 'login',
    redirect: '/login/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/login'),
      },
      {
        path: 'forgetPasswordStep1',
        name: 'forgetPasswordStep1',
        component: () => import('@/views/login/forgetPasswordStep1'),
      },
      {
        path: 'forgetPasswordStep2',
        name: 'forgetPasswordStep2',
        component: () => import('@/views/login/forgetPasswordStep2'),
      },
      {
        path: 'forgetPasswordStep3',
        name: 'forgetPasswordStep3',
        component: () => import('@/views/login/forgetPasswordStep3'),
      },
      {
        path: 'invitecode',
        name: 'invitecode',
        component: () => import('@/views/login/invitecode'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/login/register'),
      },
      {
        path: 'agreement',
        name: 'agreement',
        component: () => import('@/views/login/agreement'),
      },
      {
        path: 'web',
        name: 'web',
        component: () => import('@/views/login/web'),
      },
      {
        path: 'verifiedCaptchaLogin',
        name: 'verifiedCaptchaLogin',
        component: () => import('@/views/login/verifiedCaptcha'),
      },
    ],
  },
];
