export default [
  {
    path: '/message',
    name: 'message',
    redirect: '/message/index',
    component: {
      name: 'MessageWrapper',
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'message',
        component: () => import('@/views/message'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messagelist',
        name: 'messagelist',
        component: () => import('@/views/message/messagelist'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messageDetail',
        name: 'messageDetail',
        component: () => import('@/views/message/messageDetail'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messageSet',
        name: 'messageSet',
        component: () => import('@/views/message/messageSet'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messageNewList',
        name: 'messageNewList',
        component: () => import('@/views/message/messageNewList'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messageSysList',
        name: 'messageSysList',
        component: () => import('@/views/message/messageSysList'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messageAccountList',
        name: 'messageAccountList',
        component: () => import('@/views/message/messageAccountList'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messageAcquireList',
        name: 'messageAcquireList',
        component: () => import('@/views/message/messageAcquireList'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'mobileMessage',
        name: 'mobileMessage',
        component: () => import('@/views/message/mobileMessage'),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];
