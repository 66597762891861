// store.js
import Vue from 'vue';
import Vuex from 'vuex';

import getters from './getters';

import common from './modules/common';
import login from './modules/login';
import viewsCache from './modules/viewsCache';
import dict from './modules/dict';
import sort from './modules/sort';
import storehouse from './modules/storehouse';
import transfer from './modules/transfer';
import verified from './modules/verified';
import refuelPack from './modules/refuelPack';

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  modules: {
    common,
    login,
    viewsCache,
    dict,
    sort,
    storehouse,
    transfer,
    verified,
    refuelPack,
  },
});
