<template>
  <van-overlay
    v-model.trim="isShow"
    :show="isShow"
  >
    <div class="wrapper flex-align-center flex-justify-center">
      <div class="block">
        <div class="content">
          <p class="title">
            {{ title }}
          </p>
          <p class="desc">
            {{ desc }}
          </p>

          <div
            v-if="!isSingleAction"
            class="flex flex-justify-between"
          >
            <van-button
              class="gray"
              @click="rejectClick"
            >
              取消
            </van-button>
            <van-button
              class="blue"
              @click="agreeClick"
            >
              确定
            </van-button>
          </div>

          <div
            v-if="isSingleAction"
            class="flex flex-justify-center"
          >
            <van-button
              class="blue"
              v-debounce="agreeClick"
            >
              确定
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: `ConfirmPod`,
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: `提示信息`
    },
    desc: {
      type: String,
      default: `确定退出登录?`
    },
    isSingleAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    };
  },
  created() { },
  methods: {

    rejectClick() {
      this.show = false;
      this.$emit(`reject`, false);
    },
    agreeClick() {
      this.show = false;
      localStorage.setItem(`isAgree`, true);
      this.$emit(`agree`, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.van-overlay {
  z-index: 3000;
}

.block {
  position: relative;
  width: 250px;
  background-color: #fff;
  border-radius: 8px;;


  .content {
    position: relative;
    padding: 18px 20px;
    background: url('../assets/images/confirmpod_top_bg.svg') 0 0 no-repeat;
    background-size:250px 68px;
    // background: #fff;
    border-radius: 8px;
    overflow: hidden;
    .title {
      color: #313235;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .desc {
      padding-top: 14px;
      color: #5e6065;
      font-size: 14px;
      text-align: center;
    }

    .van-button {
      margin-top: 36px;
      padding: 0;
      border-radius: 6px;
      width: 101px;
      height: 38px;
      line-height: 38px;
    }

    .gray {
      padding: 9px 0;
      height: auto;
      line-height: normal;
      font-size: 14px;
      font-weight: 500;
      color: #313235;
      background-color: #f5f6fa;
      border-color: #f5f6fa;
    }

    .blue {
      padding: 9px 0;
      height: auto;
      line-height: normal;
      font-size: 14px;
      font-weight: 500;
      background-color: #2e5bfd;
      color: #fff;
    }
  }
}
</style>
