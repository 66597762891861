<template>
  <div v-cloak id="app" class>
    <keep-alive :include="$store.state.viewsCache.caches">
      <router-view />
    </keep-alive>
    <v-loading v-if="fetchLoading" />
    <div class="hiddenImg">
      <svg-icon v-for="(item, index) in imgList" :key="index" :icon-class="item" />
      <!--  <img
        v-for="(i,index) in imgList"
        :key="index"
        :src="i"
        alt="tab"
      > -->
    </div>
    <!--底部导航栏-->
    <TabBar v-show="showBar" />
  </div>
</template>

<script>
import Loading from '@/common/_loading';
import store from '@/vuex/store.js';
export default {
  components: {
    'v-loading': Loading,
  },
  data() {
    return {
      imgList: [
        // 首页tab组件
        'icon_home_active',
        'icon_dev_active',
        'icon_message_active',
        'icon_mine_active',
      ],
      // 是否显示BAR
      showBar: false,
      // 需要显示bar的路由名称
      showBarList: [`home`, `message`, `newBusiness`, `user`, `business`],
    };
  },
  computed: {
    fetchLoading() {
      return store.state.common.fetchLoading;
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.showBarList.includes(this.$route.name)) {
          this.showBar = true;
        } else {
          this.showBar = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    localStorage.removeItem('app-title');
    if (process.env.NODE_ENV !== `production`) {
      localStorage.setItem(`vConsole_switch_x`, 0);
      localStorage.setItem(`vConsole_switch_y`, 200);
      new VConsole();
    }
  },
};
</script>
<style lang="scss" scoped>
.hiddenImg {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
