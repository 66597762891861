export default [
  {
    path: '/user',
    redirect: '/user/index',
    component: {
      name: 'UserWrapper',
      render() {
        return (
          <keep-alive
            include={['integralIndex', ...this.$store.state.viewsCache.caches]}
          >
            <router-view />
          </keep-alive>
          /*{ <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive> }*/
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'user',
        component: () => import('@/views/user'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import('@/views/user/coupon'),
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/views/user/account'),
      },
      {
        path: 'withdraw',
        name: 'withdraw',
        component: () => import('@/views/user/account/withdraw'),
      },
      {
        path: 'addAliPay',
        name: 'addAliPay',
        component: () => import('@/views/user/account/addAliPay'),
      },
      {
        path: 'withdrawResult',
        name: 'withdrawResult',
        component: () => import('@/views/user/account/withdrawResult'),
      },
      {
        path: 'billList',
        name: 'billList',
        component: () => import('@/views/user/account/billList'),
      },
      {
        path: 'billDetail',
        name: 'billDetail',
        component: () => import('@/views/user/account/billDetail'),
      },
      {
        path: 'sign',
        name: 'sign',
        component: () => import('@/views/user/account/sign'),
      },
      {
        path: 'signSecond',
        name: 'signSecond',
        component: () => import('@/views/user/account/signSecond'),
      },
      {
        path: 'signResult',
        name: 'signResult',
        component: () => import('@/views/user/account/signResult'),
      },
      {
        path: 'shipping',
        name: 'userShipping',
        component: () => import('@/views/user/shipping'),
      },
      {
        path: 'addAddress',
        name: 'addAddress',
        component: () => import('@/views/user/addAddress'),
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('@/views/user/setting'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/user/setting/about'),
      },
      {
        path: 'logoutInfo',
        name: 'logoutInfo',
        component: () => import('@/views/user/setting/logoutInfo'),
      },
      {
        path: 'forgetLogoutInfo',
        name: 'forgetLogoutInfo',
        component: () => import('@/views/user/setting/forgetLogoutInfo'),
      },
      {
        path: 'logoutSuccess',
        name: 'logoutSuccess',
        component: () => import('@/views/user/setting/logoutSuccess'),
      },
      {
        path: 'logoutError',
        name: 'logoutError',
        component: () => import('@/views/user/setting/logoutError'),
      },
      {
        path: 'verifiedCaptcha',
        name: 'verifiedCaptcha',
        component: () => import('@/views/user/setting/verifiedCaptcha'),
      },
      {
        path: 'changePhone',
        name: 'changePhone',
        component: () => import('@/views/user/setting/changePhone'),
      },
      {
        path: 'personal',
        name: 'personal',
        component: () => import('@/views/user/personal'),
      },
      {
        path: 'realname',
        name: 'realname',
        component: () => import('@/views/user/personal/realname'),
      },
      {
        path: 'modifyBankcard1',
        name: 'modifyBankcard1',
        component: () => import('@/views/user/personal/modifyBankcard1'),
      },
      {
        path: 'modifyBankcard2',
        name: 'modifyBankcard2',
        component: () => import('@/views/user/personal/modifyBankcard2'),
      },
      {
        path: 'selectBranch',
        name: 'selectBranch',
        component: () => import('@/views/user/personal/selectBranch'),
      },
      {
        path: 'modifyAlipay1',
        name: 'modifyAlipay1',
        component: () => import('@/views/user/personal/modifyAlipay1'),
      },
      {
        path: 'modifyAlipay2',
        name: 'modifyAlipay2',
        component: () => import('@/views/user/personal/modifyAlipay2'),
      },
      {
        path: 'openApp',
        name: 'openApp',
        component: () => import('@/views/user/personal/openApp'),
      },
      {
        path: 'downloadApp',
        name: 'downloadApp',
        component: () => import('@/views/user/personal/downloadApp'),
      },
      {
        path: 'uploadHandcard',
        name: 'uploadHandcard',
        component: () => import('@/views/user/personal/uploadHandcard'),
      },
      // 服务费页面
      {
        path: 'serviceFee',
        name: 'ServiceFee',
        component: () => import('@/views/user/serviceFee'),
      },
      // 服务费页面
      {
        path: 'serviceFeeDetail',
        name: 'serviceFeeDetail',
        component: () => import('@/views/user/serviceFee/serviceFeeDetail'),
      },
      // 联系我们
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/user/contact'),
      },
      // 分享
      {
        path: 'invite',
        name: 'invite',
        component: () => import('@/views/user/invite'),
      },
      // 展业校验
      {
        path: 'zhanye',
        name: 'zhanye',
        component: () => import('@/views/user/zhanye'),
      },
      // 账号与安全
      {
        path: 'accountSafe',
        name: 'accountSafe',
        component: () => import('@/views/user/setting/accountSafe'),
      },
      // 设备管理
      {
        path: 'machineManage',
        name: 'machineManage',
        component: () => import('@/views/user/setting/machineManage'),
      },
      // 修改头像
      {
        path: 'modifyAvatar',
        name: 'modifyAvatar',
        component: () => import('@/views/user/personal/modifyAvatar'),
      },
      // 积分
      {
        path: 'integralIndex',
        name: 'integralIndex',
        component: () => import('@/views/user/integral'),
      },
      // 积分账单
      {
        path: 'integralList',
        name: 'integralList',
        component: () => import('@/views/user/integral/integralList'),
      },
      // 积分
      {
        path: 'integralShopDetail',
        name: 'integralShopDetail',
        component: () => import('@/views/user/integral/integralShopDetail'),
      },
      // 积分
      {
        path: 'integralOrderConfirm',
        name: 'integralOrderConfirm',
        component: () => import('@/views/user/integral/orderConfirm'),
      },
      {
        path: 'integralOrderCashier',
        name: 'integralOrderCashier',
        component: () => import('@/views/user/integral/cashier'),
      },
      {
        path: 'integralOrderResult',
        name: 'integralOrderResult',
        component: () => import('@/views/user/integral/orderResult'),
      },
      {
        path: 'integralOrderDetail',
        name: 'integralOrderDetail',
        component: () => import('@/views/user/integral/orderDetail'),
      },
      {
        path: 'integralLuck',
        name: 'integralLuck',
        component: () => import('@/views/user/integral/integralLuck'),
      },
      {
        path: 'integralLuckReward',
        name: 'integralLuckReward',
        component: () => import('@/views/user/integral/integralLuckReward'),
      },
    ],
  },
];
