<template>
  <div>
    <van-nav-bar
      class="navbar"
      :class="classObj[type]"
      :title="title"
      :border="false"
      :fixed="true"
      left-text=""
      right-text=""
      :safe-area-inset-top="true"
      v-bind="$attrs"
      v-on="$listeners"
      @click-left="onClickLeft"
      :style="{
        backgroundColor: bgColor,
        color: whiteArrowArr.includes(type)
          ? '#fff !important'
          : '#000 !important',
      }"
    >
      <template #left v-if="hasBack">
        <slot name="left">
          <svg-icon
            v-if="blackArrowArr.includes(type)"
            class="arrow"
            icon-class="icon_arrow_left_black"
          />
          <svg-icon
            v-if="whiteArrowArr.includes(type)"
            class="arrow"
            icon-class="icon_arrow_left_white"
          />
        </slot>
      </template>
      <template #title>
        <slot name="title">
          <span
            :style="{
              color: whiteArrowArr.includes(type)
                ? '#fff !important'
                : '#000 !important',
            }"
          >
            {{ title }}
          </span>
        </slot>
      </template>
      <template #right>
        <slot name="right" />
      </template>
    </van-nav-bar>
    <div v-if="hasPlaceholder" class="placeholder" />
  </div>
</template>

<script>
export default {
  name: `Navbar`,
  props: {
    // 传white 或者 black 修改文字与箭头颜色
    type: {
      type: String,
      default: `1`,
    },
    hasBack:{
      type:Boolean,
      default:true
    },
    title: {
      type: String,
      default: ``,
    },
    hasPlaceholder: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: ``,
    },
    // 是否自定义回退事件
    customBack: {
      type: Boolean,
      default: false,
    }
    // hasBorder: {
    //     type: Boolean,
    //     default: true
    // }
  },
  data() {
    return {
      classObj: {
        0: `transparent-theme`,
        1: `white-theme`,
        2: `blue-theme`,
        3: `gray-theme`,
        4: `deep-blue-theme`,
        5: `orange-theme`,
        6: `black-theme`,
        7: `rank-theme`,
        8: `card-mission`,
        9: `refuelPack-theme`,
        10: `zy_activity`,
      },
      whiteArrowArr: [`2`, `4`, `5`, `6`, `7`, `8`, 'white'],
      blackArrowArr: [`0`, `1`, `3`, `9`, '10', 'black'],
    };
  },
  methods: {
    onClickLeft() {
      if (!this.customBack) {
        this.$router.back();
      } else {
        this.$emit('customBackMethod');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-nav-bar {
  height: 44px;
  ::v-deep .van-nav-bar__content {
    height: 44px;
  }
}
.arrow {
  width: 22px;
  height: 22px;
}
.navbar {
  padding-top: var(--status-bar-height);
  width: 100%;
  z-index: 2000;
}
.placeholder {
  padding-top: var(--status-bar-height);
  height: 44px;
}
.transparent-theme {
  background-color: transparent;
  ::v-deep .van-nav-bar__title {
    color: #323233;
  }
}
.white-theme {
  background-color: #fff;
  ::v-deep .van-nav-bar__content {
    background: #fff;
  }
  ::v-deep .van-nav-bar__title {
    color: #323233;
  }
}
.blue-theme {
  background-color: #2e5bfd;
  ::v-deep .van-nav-bar__content {
    background: #2e5bfd;
  }
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
}
.deep-blue-theme {
  background-color: #2948ff;
  ::v-deep .van-nav-bar__content {
    background: #2948ff;
  }
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
}
.gray-theme {
  background-color: #f3f5f9;
  ::v-deep .van-nav-bar__content {
    background: #f3f5f9;
  }
  ::v-deep .van-nav-bar__title {
    color: #323233;
  }
}
.orange-theme {
  background-color: #f24a39;
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
}
.black-theme {
  background-color: #121622;
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
}

.refuelPack-theme {
  background-color: #e1f1ff;
  ::v-deep .van-nav-bar__title {
    color: #323233;
  }
}

.rank-theme {
  background-color: #f15235;
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
}
.deep-theme {
  background-color: #121622;
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
}

.card-mission {
  background-color: #ff1e2e;
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
}

.zy_activity {
  //background: unset;
}
.arrow {
  width: 22px !important;
  height: 22px !important;
}
</style>
