const state = {
  selectedAgent: {}
}

const mutations = {
  SET_SELECTED_AGENT: (state, agent) => {
    state.selectedAgent = agent
  },
  RESET_SELECTED_AGENT: state => {
    state.selectedAgent = {}
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
