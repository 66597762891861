import Prompt from '@/components/prompt';

let Vue;
let instance;

function initInstance() {
  instance = new (Vue.extend(Prompt))({
    propsData: {
      value: false
    }
  });
  instance.$on(`input`, res => {
    if (!res) {
      close();
      instance.reject();
    }
  });
  instance.$on(`confirm`, () => {
    close();
    instance.resolve();
  });
  instance.$mount();
  document.body.appendChild(instance.$el);
}

function prompt(options = {}) {
  const { desc } = options;
  instance.$slots.default = typeof desc !== `string` ? [desc] : null;

  return new Promise((resolve, reject) => {
    Object.assign(instance, {
      ...options,
      value: true,
      resolve,
      reject
    });
  });
}

function close() {
  instance.value = false;
}

export default function(_Vue) {
  Vue = _Vue;
  initInstance();
  Vue.prototype.$prompt = prompt;
}
