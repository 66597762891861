export default [
  {
    path: '/income',
    name: '消息',
    redirect: '/income/index',
    component: {
      render() {
        return (
          <keep-alive include={['Income', 'IncomeList']}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'income',
        component: () => import('@/views/income'),
      },
      {
        path: 'incomelist',
        name: 'incomelist',
        component: () => import('@/views/income/incomeList'),
      },
      {
        path: 'incomeDetail/:id',
        name: 'incomeDetail',
        component: () => import('@/views/income/incomeDetail'),
      },
    ],
  },
];
