import { exp } from 'mathjs';
import request from '../request';

// 商品-分页
export function shop_wares_page(params) {
  return request({
    url: `/shop/wares/page`,
    method: `get`,
    params
  });
}

// 商品分类-列表
export function shop_wares_category(params) {
  return request({
    url: `/shop/wares/category`,
    method: `get`,
    params
  });
}

// 商品-详情
export function shop_wares_detail(id) {
  return request({
    url: `/shop/wares/${id}`,
    method: `get`
  });
}

// 仓库列表
export function shop_wares_depot(params) {
  return request({
    url: `/shop/wares/depot`,
    method: `get`,
    params
  });
}

// 商品详情富文本
export function shop_wares_context(id) {
  return request({
    url: `/shop/wares/context/${id}`,
    method: `get`
  });
}


// 获取代理默认收货地址（无默认200返回为空）
export function user_address_get_default(params) {
  return request({
    url: `/user/address/get/default`,
    method: `get`,
    params
  });
}

// 获取代理收货地址列表
export function user_address_list(params) {
  return request({
    url: `/user/address/list`,
    method: `get`,
    params
  });
}

// 商品物流信息
export function shop_wares_logistic(id) {
  return request({
    url: `/shop/wares/logistic/${id}`,
    method: `get`
  });
}

// 删除代理收货地址列表
export function user_address_remove(id) {
  return request({
    url: `/user/address/remove/${id}`,
    method: `post`
  });
}

// 终端商品-可用优惠券
export function market_coupon_list(params) {
  return request({
    url: `/market/coupon/list`,
    method: `get`,
    params
  });
}

// 新增代理用户地址
export function user_address_add(data) {
  return request({
    url: `/user/address/add`,
    method: `post`,
    data
  });
}

// 编辑代理用户地址
export function user_address_editor(data) {
  return request({
    url: `/user/address/editor`,
    method: `post`,
    data
  });
}

// 订单创建
export function shop_order_create(data) {
  return request({
    url: `/shop/order/create`,
    method: `post`,
    data
  });
}

// 代理账户信息
export function account_info_agent() {
  return request({
    url: `account/info/agent`,
    method: `get`
  });
}


// 订单支付
export function shop_order_pay(data) {
  return request({
    url: `/shop/order/pay`,
    method: `post`,
    data
  });
}

export const queryOrderByH5 = (id) => {
  return request({
    url: `/shop/order/payStatus/${id}`,
    method: 'GET',
  })
}
// 自提点-根据商品ID
export function shop_wares_delivery_station(id) {
  return request({
    url: `/shop/wares/delivery/station/${id}`,
    method: `get`,
  });
}