import request from '../request';

// 当月直营业绩
export function data_performance_direct(params) {
  return request({
    url: `/data/performance/direct`,
    method: `get`,
    params,
  });
}

// 当月团队业绩
export function data_performance_team(params) {
  return request({
    url: `/data/performance/team`,
    method: `get`,
    params,
  });
}

// 直推代理列表
export function data_performance_direct_agent(params) {
  return request({
    url: `/data/performance/direct/agent`,
    method: `get`,
    params,
  });
}

// 服务商统计抬头
export function data_contribute_agent_title(params) {
  return request({
    url: `/data/contribute/agent/title`,
    method: `get`,
    params,
  });
}

// 服务商统计排行
export function data_contribute_agent_rank(params) {
  return request({
    url: `/data/contribute/agent/rank`,
    method: `get`,
    params,
  });
}

// 商户统计抬头
export function data_contribute_merchant_title(params) {
  return request({
    url: `/data/contribute/merchant/title`,
    method: `get`,
    params,
  });
}

// 商户统计排行
export function data_contribute_merchant_rank(params) {
  return request({
    url: `/data/contribute/merchant/rank`,
    method: `get`,
    params,
  });
}

// 直营业绩-按日查询
export function data_performance_direct_day(params) {
  return request({
    url: `/data/performance/direct/day`,
    method: `get`,
    params,
  });
}

// 直营业绩-按月查询
export function data_performance_direct_month(params) {
  return request({
    url: `/data/performance/direct/month`,
    method: `get`,
    params,
  });
}

// 我的商户-分页查询
export function data_merchant_v2_page(params) {
  return request({
    url: `/data/merchant/v2/page`,
    method: `get`,
    params,
  });
}

// 商户详情-经营趋势
export function data_merchant_v2_trans_chart(params) {
  return request({
    url: `/data/merchant/v2/trans/chart`,
    method: `get`,
    params,
  });
}

// 商户详情-商户信息
export function data_merchant_v2_info(merchantId) {
  return request({
    url: `/data/merchant/v2/info/${merchantId}`,
    method: `get`,
  });
}

// 商户详情-设备管理
export function data_merchant_v2_device_manage(merchantId) {
  return request({
    url: `/data/merchant/v2/device/manage/${merchantId}`,
    method: `get`,
  });
}

// 商户详情-交易明细
export function data_merchant_v2_trans_detail(params) {
  return request({
    url: `/data/merchant/v2/trans/detail`,
    method: `get`,
    params,
  });
}

// 商户详情-交易明细-交易费率明细
export function data_merchant_v2_product_trans_rate(params) {
  return request({
    url: `/data/merchant/v2/product/trans/rate`,
    method: `get`,
    params,
  });
}

// 新增商户
export function data_performance_brand_merchant(params) {
  return request({
    url: `/data/performance/brand/merchant`,
    method: `get`,
    params,
  });
}

// 新增激活
export function data_performance_brand_achieve(params) {
  return request({
    url: `/data/performance/brand/achieve`,
    method: `get`,
    params,
  });
}

// 团队业绩-按日查询
export function data_performance_team_day(params) {
  return request({
    url: `/data/performance/team/day`,
    method: `get`,
    params,
  });
}

// 团队业绩-按月查询
export function data_performance_team_month(params) {
  return request({
    url: `/data/performance/team/month`,
    method: `get`,
    params,
  });
}

// 新增商户明细
export function data_performance_product_merchant(params) {
  return request({
    url: `/data/performance/product/merchant`,
    method: `get`,
    params,
  });
}

// 品牌交易排名
export function data_performance_brand_trans(params) {
  return request({
    url: `/data/performance/brand/trans`,
    method: `get`,
    params,
  });
}

// 产品业绩明细
export function data_performance_product_trans(params) {
  return request({
    url: `/data/performance/product/trans`,
    method: `get`,
    params,
  });
}

// 产品交易明细
export function data_performance_product_trans_detail(params) {
  return request({
    url: `/data/performance/product/trans/detail`,
    method: `get`,
    params,
  });
}

// 产品交易费率明细
export function data_performance_product_trans_rate(params) {
  return request({
    url: `/data/performance/product/trans/rate`,
    method: `get`,
    params,
  });
}

// 新增终端明细
export function data_performance_product_achieve(params) {
  return request({
    url: `/data/performance/product/achieve`,
    method: `get`,
    params,
  });
}

// 服务商-分页查询
export function data_agent_v2_page(params) {
  return request({
    url: `/data/agent/v2/page`,
    method: `get`,
    params,
  });
}

// 服务商-个人信息
export function data_agent_v2_info(id) {
  return request({
    url: `/data/agent/v2/info/${id}`,
    method: `get`,
  });
}

// 服务商-交易统计抬头
export function data_agent_v2_trans_title(params) {
  return request({
    url: `/data/agent/v2/trans/title`,
    method: `get`,
    params,
  });
}

// 服务商-激活统计抬头
export function data_agent_v2_achieve_title(params) {
  return request({
    url: `/data/agent/v2/achieve/title`,
    method: `get`,
    params,
  });
}

// 服务商-团队人员
export function data_agent_v2_team(params) {
  return request({
    url: `/data/agent/v2/team`,
    method: `get`,
    params,
  });
}

// 服务商详情-交易折线图
export function data_agent_v2_trans_chart(params) {
  return request({
    url: `/data/agent/v2/trans/chart`,
    method: `get`,
    params,
  });
}

// 服务商详情-激活折线图
export function data_agent_v2_achieve_chart(params) {
  return request({
    url: `/data/agent/v2/achieve/chart`,
    method: `get`,
    params,
  });
}


// 我的商户-提交更改商户
export function data_merchant_v2_to_agent(data) {
  return request({
    url: `/data/merchant/v2/level/up`,
    method: `post`,
    data,
  });
}
