import request from '../request';

// 收益明细
export function account_profit_list(params) {
  return request({
    url: `/account/profit/list`,
    method: `get`,
    params,
  });
}

/* // 获取代理收益
export function account_profit_sum(agentId, showLoading) {
  return request({
    url: `/account/profit/sum`,
    method: `get`,
    showLoading,
  });
} */

// 获取代理收益-新
export function account_profit_sum(agentId, showLoading) {
  return request({
    url: `/market/report/profit`,
    method: `get`,
    showLoading,
  });
}

// 收益报表
export function account_profit_chart(params) {
  return request({
    url: `/account/profit/chart`,
    method: `get`,
    params,
  });
}
// 收益详情
export function account_profit_info(id) {
  return request({
    url: `/account/profit/info/${id}`,
    method: `get`,
  });
}
