const getDefaultSet = () => new Set([
  `HomeWrapper`,
  `UserWrapper`,
  `MessageWrapper`,
  `BusinessWrapper`,
  `user`,
  `message`,
  `home`,
  `business`
]);
// 
let caches = getDefaultSet();
const state = {
  caches: [...caches],
};

const mutations = {
  ADD_CACHE: (state, viewName) => {
    caches.add(viewName);
    state.caches = [...caches];
  },
  REMOVE_CACHE: (state, viewName) => {
    caches.delete(viewName);
    state.caches = [...caches];
  },

  CLEAR_CACHE: state => {
    caches.clear();
    state.caches = [...caches];
  },

  INIT_CACHE: state => {
    caches = getDefaultSet();
    state.caches = [...caches];
  },

};

export default {
  namespaced: true,
  state,
  mutations,
};
