export default [
  {
    path: '/training',
    name: '训练营',
    redirect: '/training/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'training',
        component: () => import('@/views/training'),
      },
      {
        path: 'trainingDetail',
        name: 'trainingDetail',
        component: () => import('@/views/training/trainingDetail'),
      },
      {
        path: 'permission',
        name: 'TrainingPermission',
        component: () => import('@/views/training/permission'),
      },
      {
        path: 'audit',
        name: 'TrainingAudit',
        component: () => import('@/views/training/audit'),
      },
      {
        path: 'reject',
        name: 'TrainingReject',
        component: () => import('@/views/training/reject'),
      },
      {
        path: 'mycamp',
        name: 'mycamp',
        component: () => import('@/views/training/myCamp'),
      },
      {
        path: 'inviteMember',
        name: 'inviteMember',
        component: () => import('@/views/training/inviteMember'),
      },
      {
        path: 'initiate',
        name: 'initiate',
        component: () => import('@/views/training/initiate'),
      },
      {
        path: 'resultStatus',
        name: 'resultStatus',
        component: () => import('@/views/training/resultStatus'),
      }
    ],
  },
];
