import request from '../request';

// 营训-我参与的
export function market_camp_join_page(params) {
  return request({
    url: `/market/camp/join/page`,
    method: `get`,
    params,
  });
}

// 营训-我发起的
export function market_camp_create_page(params) {
  return request({
    url: `/market/camp/create/page`,
    method: `get`,
    params,
  });
}

// 营训关闭|撤销
export function market_camp_manage(data) {
  return request({
    url: `/market/camp/manage`,
    method: `post`,
    data,
  });
}

// 渠道审核-列表
export function market_camp_audit_page(params) {
  return request({
    url: `/market/camp/audit/page`,
    method: `get`,
    params,
  });
}

// 授权人列表(分页)
export function market_camp_auth_page(params) {
  return request({
    url: `/market/camp/auth/page`,
    method: `get`,
    params,
  });
}

// 删除授权
export function market_camp_auth_del(data) {
  return request({
    url: `/market/camp/auth/del`,
    method: `post`,
    data,
  });
}

// 搜索代理用户
export function market_camp_auth_search(params) {
  return request({
    url: `/market/camp/auth/search`,
    method: `get`,
    params,
  });
}

// 授权代理
export function market_camp_auth_agent(data) {
  return request({
    url: `/market/camp/auth/agent`,
    method: `post`,
    data,
  });
}

// 授权信息
export function market_camp_auth_info(params) {
  return request({
    url: `/market/camp/auth/info`,
    method: `get`,
    params,
  });
}

// 营训审核(通过|拒绝)
export function market_camp_audit(data) {
  return request({
    url: `/market/camp/audit`,
    method: `post`,
    data,
  });
}

// 授权类型转换
export function market_camp_auth_change(data) {
  return request({
    url: `/market/camp/auth/change`,
    method: `post`,
    data,
  });
}

// 训练营详情
export function market_camp_detail(params) {
  return request({
    url: `/market/camp/detail`,
    method: `get`,
    params,
  });
}

// 营训-成员列表
export function market_camp_member_page(params) {
  return request({
    url: `/market/camp/member/page`,
    method: `get`,
    params,
  });
}



// 战营排行
export function market_camp_rank(month) {
  return request({
    url: `/market/camp/rank/${month}`,
    method: `get`
  });
}

// 个人排行
export function market_camp_personal_rank(month) {
  return request({
    url: `/market/camp/personal/rank/${month}`,
    method: `get`
  });
}

// 训练营邀请信息
export function market_camp_invite_info(params) {
  return request({
    url: `/market/camp/invite/info`,
    method: `get`,
    params,
  });
}

// 加入训练营
export function market_camp_join(data) {
  return request({
    url: `/market/camp/join`,
    method: `post`,
    data,
  });
}

// 营训-成员审核
export function market_camp_member_audit(data) {
  return request({
    url: `/market/camp/member/audit`,
    method: `post`,
    data,
  });
}

// 营训战报
export function marget_camp_report(id) {
  return request({
    url: `/market/camp/report/${id}`,
    method: `get`,
  });
}

// 精英排行
export function marget_camp_elite_rank(params) {
  return request({
    url: `/market/camp/elite/rank`,
    method: `get`,
    params
  });
}

// 精英排行-本人
export function marget_camp_elite_self(id) {
  return request({
    url: `/market/camp/elite/self/${id}`,
    method: `get`,
  });
}

// 营训-成员数据
export function market_camp_member_data(params) {
  return request({
    url: `/market/camp/member/data`,
    method: `get`,
    params,
  });
}

// 营训活动列表
export function market_camp_activity_list(params) {
  return request({
    url: `/market/camp/activity/list`,
    method: `get`,
    params,
  });
}

// 营训地区列表
export function market_camp_area(params) {
  return request({
    url: `/market/camp/area`,
    method: `get`,
    params,
  });
}

// 训练营新增
export function market_camp_add(data) {
  return request({
    url: `/market/camp/add`,
    method: `post`,
    data,
  });
}

// 训练营编辑
export function market_camp_edit(data) {
  return request({
    url: `/market/camp/edit`,
    method: `post`,
    data,
  });
}
