// import { storage } from '@/utils/storage'
// import { $api } from '@/http'

const state = {
    branch: {
        code: "",
        name: "",
    }
}

const mutations = {
  SET_BRANCH: (state, dicts) => {
    state.branch = dicts
  }
}

// const actions = {
//   async getDict({ commit, state }, type) {
//     // 通过codeType，获取对应字典，进行缓存
//     const dictsObj = JSON.parse(JSON.stringify(state.dicts))
//     // 是否存在当前type字典
//     const isExist = !!dictsObj[type]

//     if (isExist) return dictsObj[type]

//     const res = await $api.common.common_dict(type)
//     const { data } = res.data

//     dictsObj[type] = data
//     storage.setItem('dicts', dictsObj)
//     commit('SET_DICTS', dictsObj)

//     return data
//   }
// }

export default {
  namespaced: true,
  state,
  mutations,
//   actions
}

