import Vue from 'vue';
import { $api } from '@/http';

const state = {
  // 当前购买的加油包
  currentPackData: {
    stepNum: 5, // 购买的最小值
    totalObj: {},
    totalNum: 0,
  },

  // 当前转让的加油包
  currentTransferData: {
    stepNum: 1, // 转让的最小值
    totalObj: {},
    totalNum: 0,
    canSelectNum: 0, // 剩余可选SN数量
    canChooseData: [], // 转让必选SN数量
  },
};
const getters = {
  canChooseData: state => state.currentTransferData.canChooseData,
};
const mutations = {
  // -----------------------购买----------------------
  // 设置当前加油包 每一个产品的数量
  SET_CURRENT_PACK_PRODUCT_NUM(state, data) {
    state.currentPackData.totalNum = 0;
    state.currentPackData.totalObj[data.id] = data;

    Object.values(state.currentPackData.totalObj).forEach(item => {
      state.currentPackData.totalNum += Number(item.count);
    });
  },

  // 设置当前加油包 总数量
  SET_CURRENT_PACK_TOTAL_NUM(state, data) {
    state.currentPackData.totalNum = data;
  },

  // 清空已选
  CLEAR_CURRENT_PACK_TOTAL_NUM(state) {
    state.currentPackData = {
      stepNum: 1, // 购买的最小值
      totalObj: {},
      totalNum: 0,
    };
  },

  // -----------------------转让----------------------

  // 设置转让的加油包 的SN
  SET_CURRENT_TRANSFER_SN(state, data) {
    state.currentTransferData.totalNum = 0;
    state.currentTransferData.totalObj[data.productId] = data;

    Object.values(state.currentTransferData.totalObj).forEach(item => {
      state.currentTransferData.totalNum += Number(item.snList?.length);
    });
  },

  // 设置转让的加油包 转让总数量
  SET_CURRENT_TRANSFER_NUM(state, data) {
    state.currentTransferData.totalNum = data;
  },

  // 设置转让的加油包 剩余可选SN数量
  SET_CURRENT_CAN_SELECT_NUM(state, data) {
    state.currentTransferData.canSelectNum = data < 0 ? 0 : data;
  },

  // 设置转让的加油包 必选SN数量
  SET_CURRENT_CAN_CHOOSE_DATA(state, data) {
    state.currentTransferData.canChooseData = data;
  },

  // 清空已选
  CLEAR_CURRENT_TRANSFER_SN(state, data) {
    state.currentTransferData = {
      totalObj: {},
      totalNum: 0,
      canSelectNum: data,
    };
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
