import request from '../request';

// 仓库列表（分页）
export function device_depot_charge_page(params) {
  return request({
    url: `/device/depot/charge/page`,
    method: `get`,
    params
  });
}


// 仓库详情
export function device_depot_detail(params) {
  return request({
    url: `/device/depot/detail`,
    method: `get`,
    params
  });
}

// 编辑仓库
export function device_depot_edit(data) {
  return request({
    url: `/device/depot/edit`,
    method: `post`,
    data,
  });
}

// 仓管列表（分页）
export function device_depot_admin_page(params) {
  return request({
    url: `/device/depot/admin/page`,
    method: `get`,
    params
  });
}

// 删除仓管
export function device_depot_remove_admin(data) {
  return request({
    url: `/device/depot/remove/admin`,
    method: `post`,
    data
  });
}

// 添加仓管-搜索仓管
export function device_depot_search_admin(params) {
  return request({
    url: `/device/depot/search/admin`,
    method: `get`,
    params
  });
}

// 添加仓管
export function device_depot_add_admin(data) {
  return request({
    url: `/device/depot/add/admin`,
    method: `post`,
    data
  });
}

// 创建仓库
export function device_depot_create(data) {
  return request({
    url: `/device/depot/create`,
    method: `post`,
    data
  });
}

// 上级仓库
export function device_depot_superior_list(params) {
  return request({
    url: `/device/depot/superior/list`,
    method: `get`,
    params,
  });
}


// 下级仓库
export function device_depot_lower_list(params) {
  return request({
    url: `/device/depot/lower/list`,
    method: `get`,
    params,
  });
}

// 平级仓库
export function device_depot_self_list(params) {
  return request({
    url: `/device/depot/self/list`,
    method: `get`,
    params,
  });
}


// 仓库与库存列表
export function device_depot_depot_and_reserve_list_by_charge(params) {
  return request({
    url: `/device/depot/depot/and/reserve/list/by/charge`,
    method: `get`,
    params,
  });
}


// 上级仓库列表(下拉)
export function device_depot_down_superior_list(params) {
  return request({
    url: `/device/depot/down/superior/list`,
    method: `get`,
    params,
  });
}

