export default [
  {
    path: '/newBusiness',
    name: 'newBusiness',
    redirect: '/newBusiness/index',
    component: {
      name: 'NewBusinessWrapper',
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
    },
    },
    children: [
      {
        path: 'index',
        name: 'newBusiness',
        component: () => import('@/views/newBusiness/index'),
      },
      {
        path: 'myMerchant',
        name: 'myMerchant',
        component: () => import('@/views/newBusiness/myMerchant'),
      },
      {
        path: 'detail',
        name: 'NewMerchantDetail',
        component: () => import('@/views/newBusiness/detail'),
      },
      {
        path: 'transactionDetails',
        name: 'transactionDetails',
        component: () => import('@/views/newBusiness/transactionDetails'),
      },
      {
        path: 'myService',
        name: 'myService',
        component: () => import('@/views/newBusiness/myService'),
      },
      {
        path: 'myServiceSearch',
        name: 'myServiceSearch',
        component: () => import('@/views/newBusiness/myServiceSearch'),
      },
      {
        path: 'performanceDetails',
        name: 'performanceDetails',
        component: () => import('@/views/newBusiness/performanceDetails'),
      },
      {
        path: 'subDetails',
        name: 'subDetails',
        component: () => import('@/views/newBusiness/subDetails'),
      },
      {
        path: 'totalTrade',
        name: 'totalTrade',
        component: () => import('@/views/newBusiness/totalTrade'),
      },
      {
        path: 'addDeviceDetails',
        name: 'addDeviceDetails',
        component: () => import('@/views/newBusiness/addDeviceDetails'),
      },
      {
        path: 'addMerchantDetails',
        name: 'addMerchantDetails',
        component: () => import('@/views/newBusiness/addMerchantDetails'),
      },
      {
        path: 'serviceDetail',
        name: 'serviceDetail',
        component: () => import('@/views/newBusiness/serviceDetail'),
      },
      {
        path: 'changeISP',
        name: 'changeISP',
        component: () => import('@/views/newBusiness/changeISP'),
      },
      {
        path: 'changeISPSuccess',
        name: 'changeISPSuccess',
        component: () => import('@/views/newBusiness/success'),
      },
    ],
  },
];
