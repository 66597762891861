export default [
  {
    path: '/shop',
    name: '在线商城',
    redirect: '/shop/index',
    // this.$store.state.viewsCache.caches,
    component: {
      render() {
        return (
          <keep-alive include={['Shop', 'Detail']}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'shopIndex',
        component: () => import('@/views/shop'),
      },
      {
        path: 'detail',
        name: '商品详情',
        component: () => import('@/views/shop/detail'),
      },
      {
        path: 'factory',
        name: 'factory',
        component: () => import('@/views/shop/factory'),
      },
      {
        path: 'shipping',
        name: 'shipping',
        component: () => import('@/views/shop/shipping'),
      },
      {
        path: 'selfMention',
        name: 'selfMention',
        component: () => import('@/views/shop/selfMention'),
      },
      {
        path: 'addAddress',
        name: 'addAddress',
        component: () => import('@/views/shop/addAddress'),
      },
      {
        path: 'result',
        name: 'result',
        component: () => import('@/views/shop/result'),
      },
      {
        path: 'cashier',
        name: 'cashier',
        component: () => import('@/views/shop/cashier'),
      },
      {
        path: 'combination',
        name: 'combination',
        component: () => import('@/views/shop/combination'),
      },
      {
        path: 'orderConfirm',
        name: 'orderConfirm',
        component: () => import('@/views/shop/orderConfirm'),
      },
    ],
  },
];
