<template>
  <van-popup
    v-bind="$attrs"
    :value="value"
    :round="true"
    :close-on-click-overlay="closeClick"
    v-on="$listeners"
  >
    <div class="dialog" :class="typeObj[type] ? typeObj[type] : 'default-bg'">
      <div v-if="showTitle" class="title">
        {{ title }}
      </div>
      <div class="desc">
        <slot>{{ desc || '' }}</slot>
      </div>
      <slot name="footer">
        <div class="btn-area">
          <!-- 提示语弹窗 -->
          <div
            v-if="oneBtn && ['我知道了', '确定'].includes(oneBtnText)"
            class="btn-group"
          >
            <div class="btn-know" @click="$emit('input', false)">
              {{ oneBtnText }}
            </div>
          </div>
          <!-- 操作弹窗 -->
          <div
            v-if="!['我知道了', '确定'].includes(oneBtnText)"
            class="btn-group"
          >
            <div class="btn-know" @click="$emit('oneBtnConfirm', false)">
              {{ oneBtnText }}
            </div>
          </div>
          <div v-if="!oneBtn" class="btn-group flex flex-middle-center">
            <div class="btn btn-cancel" @click="onCancel">
              {{ cancelBtnText }}
            </div>
            <div
              class="btn btn-sure"
              :class="{ 'btn-loan-sure': type === '1' }"
              @click="$emit('confirm', true)"
            >
              {{ confirmBtnText }}
            </div>
          </div>
        </div>
      </slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: `Prompt`,
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: `提示信息`,
    },
    desc: {
      type: String,
      default: ``,
    },
    oneBtn: {
      type: Boolean,
      default: false,
    },
    cancelBtnText: {
      type: String,
      default: `取消`,
    },
    confirmBtnText: {
      type: String,
      default: `确定`,
    },
    oneBtnText: {
      type: String,
      default: `我知道了`,
    },
    type: {
      type: String,
      default: ``,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    closeClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeObj: {
        '1': 'loan-bg',
        '2': 'va-bg',
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit(`input`, false);
      this.$emit(`cancel`);
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup--center.van-popup--round {
  border-radius: 8px;
}
.van-popup {
  width: 250px;
  min-height: 190px;
}
.default-bg {
  background: url('./images/prompt_bg.svg') 0 0 no-repeat;
  background-size: 250px 68px;
}
.loan-bg {
  background: url('./images/prompt_bg_loan.svg') 0 0 no-repeat;
  background-size: 250px 68px;
}

.va-bg {
  background: url('./images/prompt_bg_va.png') 0 0 no-repeat;
  background-size: 250px 100% !important;
  .title {
    color: #ff252f !important;
  }
  .btn-know {
    color: #fff;
    background: #ed4f4f !important;
  }
}
.dialog {
  padding: 0 16px;
  box-sizing: border-box;

  .prompt-bg {
    width: 100%;
    height: 68px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px 6px 0 0;
  }
  .title {
    font-size: 16px;
    color: #313235;
    text-align: center;
    font-weight: 600;
    padding-top: 18px;
    position: relative;
    z-index: 9;
  }
  .desc {
    font-size: 14px;
    margin-top: 18px;
    text-align: center;
    padding-bottom: 70px;
    color: #5e6065;
    position: relative;
    z-index: 9;
  }
  .btn-area {
    width: 100%;
    height: 54px;
    position: absolute;
    left: 0;
    bottom: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    z-index: 10;
  }
  .btn-group {
    padding-top: 8px;
    box-sizing: border-box;
    // border-top: 1px solid #ecedf2;
    .btn {
      width: 101px;
      height: 38px;
      font-size: 14px;
      text-align: center;
      line-height: 38px;
      border-radius: 4px;
    }
    .btn-cancel {
      color: #313235;
      background-color: #f5f6fa;
    }
    .btn-sure {
      color: #fff;
      background-color: #2e5bfd;
    }

    .btn-loan-sure {
      color: #fff;
      background-color: #f75a41;
    }

    .btn-know {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #fff;
      background-color: #2e5bfd;
      border-radius: 4px;
    }
  }
}
</style>
