export default [
  {
    path: '/order',
    name: '订单列表',
    redirect: '/order/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'order',
        component: () => import('@/views/order/index'),
      },
      {
        path: 'pendingPayment',
        name: '待付款',
        component: () => import('@/views/order/details/pendingPayment'),
      },
      {
        path: 'toBeDelivered',
        name: '待发货',
        component: () => import('@/views/order/details/toBeDelivered'),
      },
      {
        path: 'shipped',
        name: 'orderShipped',
        component: () => import('@/views/order/details/shipped'),
      },
      {
        path: 'inStock',
        name: '备货中',
        component: () => import('@/views/order/details/inStock'),
      },
      {
        path: 'pendingDelivery',
        name: '待提货',
        component: () => import('@/views/order/details/pendingDelivery'),
      },
      {
        path: 'completed',
        name: 'orderCompleted',
        component: () => import('@/views/order/details/completed'),
      },
      {
        path: 'cancelled',
        name: '已取消',
        component: () => import('@/views/order/details/cancelled'),
      },
      {
        path: 'paymentProcessing',
        name: '付款处理中',
        component: () => import('@/views/order/details/paymentProcessing'),
      },
      {
        path: 'record',
        name: 'SN记录',
        component: () => import('@/views/order/record'),
      },
      {
        path: 'express',
        name: '物流信息',
        component: () => import('@/views/order/express'),
      },
      {
        path: 'logisticsIndex',
        name: 'logisticsIndex',
        component: () => import('@/common/logistics/index'),
      },
    ],
  },
];
