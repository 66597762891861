import request from '../request';
import request_proxy from '../request_proxy';
import request_fx from '../request_fx';

// app首页展示手机卡信息
export function mobileCardCenter(params) {
  return request({
    url: `/zy/mobile/card/mobileCardCenter`,
    method: `get`,
    params
  });
}

// app手机卡分享下单
export function createV1(data) {
  return request_proxy({
    url: `/va/mobile/card/order/createV1`,
    method: `post`,
    data
  });
}

// app手机卡下单   
export function createV2(data) {
  return request_fx({
    url: `/va/mobile/card/order/createV2`,
    method: `post`,
    data
  });
}

// app手机卡查询业绩 
export function order_page(params) {
  return request({
    url: `/va/mobile/card/order/app/page`,
    method: `get`,
    params
  });
}

// app手机卡查询详情业绩 
export function order_app_detail(params) {
  return request({
    url: `/va/mobile/card/order/app/detail`,
    method: `get`,
    params
  });
}

export function order_app_commission(params) {
  return request({
    url: `/zy/mobile/card/app/commission`,
    method: `get`,
    params
  });
}