import request from '../request';

// 调拨管理-待处理
export function device_transfer_wait_deal_page(params) {
  return request({
    url: `/device/transfer/wait/deal/page`,
    method: `get`,
    params,
  });
}

// 调拨管理-待分拣
export function device_transfer_wait_choose_page(params) {
  return request({
    url: `/device/transfer/wait/choose/page`,
    method: `get`,
    params,
  });
}

// 调拨管理-已处理
export function device_transfer_have_deal_page(params) {
  return request({
    url: `/device/transfer/have/deal/page`,
    method: `get`,
    params,
  });
}

// 调拨管理-已发起
export function device_transfer_init_page(params) {
  return request({
    url: `/device/transfer/init/page`,
    method: `get`,
    params,
  });
}


// 调拨详情
export function device_transfer_detail(params) {
  return request({
    url: `/device/transfer/detail`,
    method: `get`,
    params,
  });
}


// 审核拒绝
export function device_transfer_check_refuse(data) {
  return request({
    url: `/device/transfer/check/refuse`,
    method: `post`,
    data,
  });
}

// 审核通过
export function device_transfer_check_pass(data) {
  return request({
    url: `/device/transfer/check/pass`,
    method: `post`,
    data,
  });
}


// 确认入库
export function device_transfer_check_confirm(data) {
  return request({
    url: `/device/transfer/check/confirm`,
    method: `post`,
    data,
  });
}

// 撤销调拨
export function device_transfer_check_revoke(data) {
  return request({
    url: `/device/transfer/check/revoke`,
    method: `post`,
    data,
  });
}

// 调拨管理-统计
export function transfer_page_count() {
  return request({
    url: `/device/transfer/page/count`,
    method: `get`
  });
}

// 库存列表（分页）
export function device_transfer_reserve_group_page(params) {
  return request({
    url: `/device/transfer/reserve/group/page`,
    method: `get`,
    params
  });
}

// 调入调出
export function device_transfer_add(data) {
  return request({
    url: `/device/transfer/add`,
    method: `post`,
    data,
  });
}
// 库存详情-列表（分页）
export function device_transfer_reserve_page(params) {
  return request({
    url: `/device/transfer/reserve/page`,
    method: `get`,
    params
  });
}

// 箱号列表
export function device_transfer_reserve_box_list(params) {
  return request({
    url: `/device/transfer/reserve/box/list`,
    method: `get`,
    params
  });
}

// SN列表
export function device_transfer_reserve_sn_list(params) {
  return request({
    url: `/device/transfer/reserve/sn/list`,
    method: `get`,
    params
  });
}

/* 调拨分拣部分 */
// 箱号列表
export function device_choose_box_list(params) {
  return request({
    url: `/device/choose/box/list`,
    method: `get`,
    params
  });
}

// 分拣提交
export function device_choose_commit(data) {
  return request({
    url: `/device/choose/commit`,
    method: `post`,
    data
  });
}

// 选完箱号|SN展示列表
export function device_choose_finish_list(params) {
  return request({
    url: `/device/choose/finish/list`,
    method: `get`,
    params
  });
}

// 分拣信息
export function device_choose_info(params) {
  return request({
    url: `/device/choose/info`,
    method: `get`,
    params
  });
}

// SN列表
export function device_choose_sn_list(params) {
  return request({
    url: `/device/choose/sn/list`,
    method: `get`,
    params
  });
}

// SN详情（分页）
export function device_transfer_sn_page(params) {
  return request({
    url: `/device/transfer/sn/page`,
    method: `get`,
    params
  });
}

// APP分页表头
export function device_deduct_app_record_title(params) {
  return request({
    url: `/device/depot/deduct/app/record/title`,
    method: `get`,
    params
  });
}

// APP分页查询
export function depot_deduct_app_record_page(params) {
  return request({
    url: `/device/depot/deduct/app/record/page`,
    method: `get`,
    params
  });
}

// 仓库下拉列表
export function device_depot_deduct_app_depot_pull(params) {
  return request({
    url: `/device/depot/deduct/app/depot/pull`,
    method: `get`,
    params
  });
}

