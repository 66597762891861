import request from '../request';

// 分拣列表（分页）
export function agent_device_choose_order_page(params) {
  return request({
    url: `/device/choose/order/page`,
    method: `get`,
    params
  });
}

// 分拣信息
export function device_choose_order_info(params) {
  return request({
    url: `/device/choose/order/info`,
    method: `get`,
    params
  });
}

// 箱号列表
export function device_choose_order_choose_box_list(params) {
  return request({
    url: `/device/choose/order/choose/box/list`,
    method: `get`,
    params
  });
}

// 挑选SN列表
export function device_choose_order_choose_sn_list(params) {
  return request({
    url: `/device/choose/order/choose/sn/list`,
    method: `get`,
    params
  });
}

// 选完箱号|SN展示列表
export function device_choose_order_choose_finish_list(params) {
  return request({
    url: `/device/choose/order/choose/finish/list`,
    method: `get`,
    params
  });
}

// 分拣提交
export function device_choose_order_choose_commit(data) {
  return request({
    url: `/device/choose/order/choose/commit`,
    method: `post`,
    data
  });
}

// SN详情（分页）
export function device_choose_order_sn_page(params) {
  return request({
    url: `/device/choose/order/sn/page`,
    method: `get`,
    params
  });
}

// 自提核销
export function choose_order_verify(data) {
  return request({
    url: `/device/choose/order/verify`,
    method: `post`,
    data
  });
}

// 核销信息
export function device_choose_order_verify_info(params) {
  return request({
    url: `/device/choose/order/verify/info`,
    method: `get`,
    params
  });
}

// 物流信息
export function choose_order_logistics_info(params) {
  return request({
    url: `/device/choose/order/logistics/info`,
    method: `get`,
    params
  });
}

// 分拣列表-统计
export function device_choose_order_page_count(showLoading) {
  return request({
    url: `/device/choose/order/page/count`,
    method: `get`,
    showLoading,
  });
}
