import * as types from '../types';
const state = {
  token: 0,
  role: 0,
  ReviewAccount: false,
};

const mutations = {
  [types.CHANGE_TOKEN](state,res) {
    state.token = res;
  },
  SET_ROLE: (state, role) => {
    state.role = role;
  },
  SET_ReviewAccount: (state, role) => {
    state.ReviewAccount = role;
  }
};

const getters = {
  get_ReviewAccount: state => state.ReviewAccount
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
