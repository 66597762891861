export default [
  {
    path: '/machine',
    redirect: '/machine/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'Machine',
        component: () => import('@/views/machine/index'),
      },
      // 机具查询
      {
        path: 'query',
        name: 'MachineQuery',
        component: () => import('@/views/machine/query'),
      },
      // 机具搜索
      {
        path: 'search',
        name: 'MachineSearch',
        component: () => import('@/views/machine/search'),
      },
      // 机具转让
      {
        path: 'assign',
        name: 'MachineAssign',
        component: () => import('@/views/machine/assign'),
      },
      {
        path: 'chooseReceiver',
        name: 'ChooseReceiver',
        component: () => import('@/views/machine/chooseReceiver'),
      },
      {
        path: 'result',
        name: 'AssignResult',
        component: () => import('@/views/machine/result'),
      },
      // 转让记录
      {
        path: 'record',
        name: 'AssignRecord',
        component: () => import('@/views/machine/record'),
      },
      // 机具接收
      {
        path: 'receive',
        name: 'MachineReceive',
        component: () => import('@/views/machine/receive'),
      },
      // 扣款记录
      {
        path: 'deduction',
        name: 'DeductionList',
        component: () => import('@/views/machine/deduction'),
      },
      {
        path: 'deductionDetail',
        name: 'DeductionDetail',
        component: () => import('@/views/machine/deduction/deductionDetail'),
      },
      // 转让详情
      {
        path: 'assignDetail/:type',
        name: 'MachineAssignDetail',
        component: () => import('@/views/machine/assignDetail'),
      },
      // 机具详情
      {
        path: 'detail/:id',
        name: 'MachineDetail',
        component: () => import('@/views/machine/detail'),
      },
      // xyfActive
      {
        path: 'xyfActive',
        name: 'xyfActive',
        component: () => import('@/views/machine/xyfActive'),
      },
      {
        path: 'xyfActivedetail',
        name: 'xyfActivedetail',
        component: () => import('@/views/machine/xyfActivedetail'),
      },
      // 设置机具活动
      {
        path: 'setActive',
        name: 'SetActive',
        component: () => import('@/views/machine/setActive'),
      },
      // 设置机具活动结果页面
      {
        path: 'activeResult',
        name: 'ActiveResult',
        component: () => import('@/views/machine/setActive/activeResult'),
      },
    ],
  },
];
