<template>
  <div class="no-data-area">
    <svg-icon class="icon-no-data" :icon-class="iconAddress" />
    <p class="no-data-title" :style="{ color: fontColor }">
      {{ info || '暂无数据' }}
    </p>
  </div>
</template>

<script>
export default {
  name: `NoData`,
  props: {
    // address list message internet coupon data
    type: {
      type: String,
      default: `data`
    },
    info: {
      type: String,
      default: `暂无数据`
    },
    fontColor: {
      type: String,
      default: `#5e6065`
    }
  },
  data() {
    return {

    };
  },
  computed: {
    iconAddress() {
      return `no_${this.type}`;
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.no-data-area {
  width: 160px;
  height: 50vh;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon-no-data {
  width: 160px !important;
  height: 160px !important;
}
.no-data-title {
  font-size: 13px;
  color: #5e6065;
  text-align: center;
}
</style>