<template>
  <div :key="Date.now()">
    <van-tabbar v-model="active" active-color="#2E5BFD" inactive-color="#93949D" route @change="onChange">
      <van-tabbar-item v-for="(item, index) in tabbarList" :key="index" :name="item.to"
        :class="{ 'van-tabbar-item--active': item.to === `/${routerPath}` }"
        :badge="item.to === '/message' ? unReadMessageNum || '' : ''">
        <template #icon>
          <svg-icon :icon-class="item.to == `/${routerPath}` ? item.active_img : item.default_img
            " />
        </template>
        <span>{{ item.label }}</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: `TabBar`,
  props: {
    type: {
      type: String,
      default: `1`,
    },
  },
  data() {
    return {
      active: ``,
      tabbarList: [
        {
          to: `/home`,
          label: `首页`,
          active_img: 'icon_home_active',
          default_img: 'icon_home_default',
        },
        {
          to: `/newBusiness`,
          label: `业绩`,
          active_img: 'icon_dev_active',
          default_img: 'icon_dev_default',
        },
        {
          to: `/message`,
          label: `消息`,
          active_img: 'icon_message_active',
          default_img: 'icon_message_default',
        },
        {
          to: `/user`,
          label: `我的`,
          active_img: 'icon_mine_active',
          default_img: 'icon_mine_default',
        },
      ],
      routerPath: `/user`
    };
  },
  computed: {
    ...mapGetters(`login`, ['get_ReviewAccount']),
    ...mapGetters(['unReadMessageNum']),
  },
  watch: {
    $route: {
      handler: function () {
        this.routerPath = this.$route.name;
      },
      deep: true,
    },
    // get_ReviewAccount: {
    //   handler: function (e) {
    //     if (e) {
    //       this.tabbarList = [
    //         {
    //           to: `/home`,
    //           label: `首页`,
    //           active_img: 'icon_home_active',
    //           default_img: 'icon_home_default',
    //         },
    //         {
    //           to: `/message`,
    //           label: `消息`,
    //           active_img: 'icon_message_active',
    //           default_img: 'icon_message_default',
    //         },
    //         {
    //           to: `/user`,
    //           label: `我的`,
    //           active_img: 'icon_mine_active',
    //           default_img: 'icon_mine_default',
    //         },
    //       ];
    //     } else {
    //       this.tabbarList = [
    //         {
    //           to: `/home`,
    //           label: `首页`,
    //           active_img: 'icon_home_active',
    //           default_img: 'icon_home_default',
    //         },
    //         {
    //           to: `/newBusiness`,
    //           label: `业绩`,
    //           active_img: 'icon_dev_active',
    //           default_img: 'icon_dev_default',
    //         },
    //         {
    //           to: `/message`,
    //           label: `消息`,
    //           active_img: 'icon_message_active',
    //           default_img: 'icon_message_default',
    //         },
    //         {
    //           to: `/user`,
    //           label: `我的`,
    //           active_img: 'icon_mine_active',
    //           default_img: 'icon_mine_default',
    //         },
    //       ];
    //     }
    //   },
    //   deep: true,
    // }
  },
  // mounted() {
  //   this.iftabbarList()
  //   console.log('mounted')
  // },
  beforeUpdate () {
    this.iftabbarList()
  },
  methods: {
    async onChange(path) {
      if (path !== '/message') {
        this.getUnReadMessageNum()
      }
      if (path === `/newBusiness` && !JSON.parse(localStorage.getItem(`userInfo`)).realnameFlag) {
        this.$router.push({ name: `attention` });
      } else {
        this.$router.replace({ path });
      }
    },
    isRealNameMethod() {
      console.log(`real`, JSON.parse(localStorage.getItem(`userInfo`)).realnameFlag);
      return JSON.parse(localStorage.getItem(`userInfo`)).realnameFlag;
    },
    //判断是否需要隐藏
    iftabbarList() {
      if (this.ifcommercial() == true && this.tabbarList[1].label != '业绩') {
        console.log('this.ifcommercial()=>',this.ifcommercial())
        this.tabbarList.splice(1, 0, {
          to: `/newBusiness`,
          label: `业绩`,
          active_img: 'icon_dev_active',
          default_img: 'icon_dev_default',
        })
      }
      if (this.ifcommercial() == false && this.tabbarList[1].label == '业绩') {
        console.log('this.ifcommercial()=>',this.ifcommercial())
        this.tabbarList.splice(1,1)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabbar {
  z-index: 1000;
}

::v-deep .van-tabbar-item {
  text-align: center;

  .van-tabbar-item__icon .svg-icon {
    display: block;
    width: 20px !important;
    height: 20px !important;
    margin: 0 auto 4px;
  }
}

::v-deep .van-tabbar-item--active {
  color: #2e5bfd !important;
}
</style>
