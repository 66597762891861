export default [
  {
    path: '/increase',
    name: '增值',
    redirect: '/increase/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'increase',
        component: () => import('@/views/increase'),
      },
      {
        path: 'explanation',
        name: 'explanation',
        component: () => import('@/views/increase/explanation'),
      },
      {
        path: 'loanExplanation',
        name: 'loanExplanation',
        component: () => import('@/views/increase/loanExplanation'),
      },
      {
        path: 'progressRate',
        name: 'progressRate',
        component: () => import('@/views/increase/progressRate'),
      },
      {
        path: 'cardOrder',
        name: 'cardOrder',
        component: () => import('@/views/increase/cardOrder'),
      },
      {
        path: 'cardOrderVa',
        name: 'cardOrderVa',
        component: () => import('@/views/increase/cardOrderVa'),
      },
      {
        path: 'loanOrder',
        name: 'loanOrder',
        component: () => import('@/views/increase/loanOrder'),
      },
      {
        path: 'loan',
        name: 'loan',
        component: () => import('@/views/increase/loan'),
      },
      {
        path: 'member',
        name: 'member',
        component: () => import('@/views/increase/member'),
      },
      {
        path: 'loanDetail',
        name: 'loanDetail',
        component: () => import('@/views/increase/loanDetail'),
      },
      {
        path: 'detail',
        name: 'increaseDetail',
        component: () => import('@/views/increase/detail'),
      },
      {
        path: 'share',
        name: 'share',
        component: () => import('@/views/increase/share'),
      },
      {
        path: 'otherApply',
        name: 'otherApply',
        component: () => import('@/views/increase/otherApply'),
      },
      //  幸运大抽奖
      {
        path: 'luck',
        name: 'luck',
        component: () => import('@/views/increase/luck/'),
      },
      //  我的奖品列表
      {
        path: 'myLuck',
        name: 'myLuck',
        component: () => import('@/views/increase/luck/myLuck.vue'),
      },
      // 办卡中心我的
      {
        path: 'cardUser',
        name: 'cardUser',
        component: () => import('@/views/increase/cardUser'),
      },
      // 办卡中心我的
      {
        path: 'searchBank',
        name: 'searchBank',
        component: () => import('@/views/increase/searchBank'),
      },
      {
        path: 'vaRanking',
        name: 'vaRanking',
        component: () => import('@/views/increase/vaRanking'),
      },
      {
        path: 'cardMissionReward',
        name: 'cardMissionReward',
        component: () => import('@/views/increase/cardMissionReward'),
      },
      {
        path: 'myCardMissionReward',
        name: 'myCardMissionReward',
        component: () => import('@/views/increase/myCardMissionReward'),
      },
      {
        path: 'myVaData',
        name: 'myVaData',
        component: () => import('@/views/increase/myVaData'),
      },
      {
        path: 'activity',
        name: 'increaseActivity',
        component: () => import('@/views/increase/activity/index'),
      },
      {
        path: 'cardOrderDetail/:id?',
        name: 'cardOrderDetail',
        component: () => import('@/views/increase/cardOrderDetail'),
      },
      {
        path: 'loanOrderDetail/:id?',
        name: 'loanOrderDetail',
        component: () => import('@/views/increase/loanOrderDetail'),
      },
      {
        path: 'promotion/:id?',
        name: 'promotion',
        component: () => import('@/views/increase/activity/promotion'),
      },
      {
        path: 'promotionReward',
        name: 'promotionReward',
        component: () => import('@/views/increase/activity/promotionReward'),
      },
      {
        path: 'promotionRewardDetail/:id?',
        name: 'promotionRewardDetail',
        component: () =>
          import('@/views/increase/activity/promotionRewardDetail'),
      },
      {
        path: 'newUser/:id?',
        name: 'newUser',
        component: () => import('@/views/increase/activity/newUser'),
      },
      {
        path: 'myLoadData',
        name: 'myLoadData',
        component: () => import('@/views/increase/myLoadData'),
      },
    ],
  },
];
