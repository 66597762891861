export default [
  {
    path: '/newIncome',
    // name: '消息',
    redirect: '/newIncome/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      // 新收益报表首页
      {
        path: 'index',
        name: 'NewIncome',
        component: () => import('@/views/newIncome'),
      },
      // 新收益报表日选择页面
      {
        path: 'calendar',
        name: 'Calendar',
        component: () => import('@/views/newIncome/calendar'),
      },
      // 新收益报表月选择页面
      {
        path: 'calendarMonth',
        name: 'CalendarMonth',
        component: () => import('@/views/newIncome/calendarMonth'),
      },
      // 新收益报表-增值收益
      {
        path: 'incrementIncome',
        name: 'IncrementIncome',
        component: () => import('@/views/newIncome/incrementIncome'),
      },
      // 新收益报表-增值收益-推荐办卡
      {
        path: 'cardList',
        name: 'CardList',
        component: () => import('@/views/newIncome/incrementIncome/cardList'),
      },
      // 新收益报表-增值收益-推荐贷款
      {
        path: 'loadList',
        name: 'LoadList',
        component: () => import('@/views/newIncome/incrementIncome/loadList'),
      },
      // 新收益报表-增值收益-活动奖励
      {
        path: 'activeList',
        name: 'ActiveList',
        component: () => import('@/views/newIncome/incrementIncome/activeList'),
      },
      // 新收益报表-分享
      {
        path: 'invite',
        name: 'Invite',
        component: () => import('@/views/newIncome/invite'),
      },
      // 新收益报表-其他收益
      {
        path: 'otherIncome',
        name: 'OtherIncome',
        component: () => import('@/views/newIncome/otherIncome'),
      },
      // 新收益报表- 终端收益
      {
        path: 'terminalIncome',
        name: 'TerminalIncome',
        component: () => import('@/views/newIncome/terminalIncome'),
      },
      // 新收益报表- 分润收益
      {
        path: 'shareIncome',
        name: 'ShareIncome',
        component: () => import('@/views/newIncome/shareIncome'),
      },
      // 新收益报表- 分润收益明细
      {
        path: 'shareIncomeDetail',
        name: 'ShareIncomeDetail',
        component: () => import('@/views/newIncome/shareIncome/shareIncomeDetail'),
      },
      // 新收益报表- 分润收益明细-品牌名称
      {
        path: 'shareIncomeTable',
        name: 'ShareIncomeTable',
        component: () => import('@/views/newIncome/shareIncome/shareIncomeTable'),
      },
    ],
  },
];
