<template>
  <div>
    <van-tabbar
      v-model="active"
      active-color="#2D2F38"
      inactive-color="#93949D"
      :route="true"
      @change="onChange"
    >
      <van-tabbar-item
        v-for="(item, index) of tabList"
        :key="index"
        :replace="true"
        :to="item.to"
        v-show="item.show"
      >
        <template #icon="props">
          <svg-icon
            :icon-class="props.active ? item.active_img : item.default_img"
          />
        </template>
        <span>{{ item.label }}</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: `CardLoanTabBar`,
  props: {
    cur: {
      type: String,
      default: ``,
    },
  },
  data() {
    return {
      active: 0,
      memberLevelModelFlag: false,
      tabList: [],
    };
  },
  mounted() {
    const memberLevelModelFlag = JSON.parse(
      localStorage.getItem('userInfo')
    ).memberLevelModelFlag;
    const tabType = localStorage.getItem('tabType');
    if (tabType == 'increase') {
      console.log('increase')
      this.tabList = [
        {
          to: `/increase/index`,
          label: `办卡`,
          active_img: 'icon_zy_card_active',
          default_img: 'icon_zy_card',
          show: true,
        },
        {
          to: `/increase/activity`,
          label: `活动`,
          active_img: 'icon_zy_gift_active',
          default_img: 'icon_zy_gift',
          show: memberLevelModelFlag,
        },
        {
          to: `/increase/vaRanking`,
          label: `排行榜`,
          active_img: 'icon_zy_rank_active',
          default_img: 'icon_zy_rank',
          show: memberLevelModelFlag,
        },
        {
          to: `/increase/member`,
          label: `会员`,
          active_img: 'icon_zy_vip_active',
          default_img: 'icon_zy_vip',
          show: memberLevelModelFlag,
        },
        {
          to: `/increase/cardUser`,
          label: `我的`,
          active_img: 'icon_zy_my_active',
          default_img: 'icon_zy_my',
          show: this.isSpecialOrg(),
        },
      ];
    } else if (tabType == 'loan') {
      this.tabList = [
        {
          to: `/increase/loan`,
          label: `贷款`,
          active_img: 'icon_zy_loan_active',
          default_img: 'icon_zy_loan',
          show: true,
        },
        {
          to: `/increase/activity`,
          label: `活动`,
          active_img: 'icon_zy_gift_active',
          default_img: 'icon_zy_gift',
          show: memberLevelModelFlag,
        },
        {
          to: `/increase/vaRanking`,
          label: `排行榜`,
          active_img: 'icon_zy_rank_active',
          default_img: 'icon_zy_rank',
          show: memberLevelModelFlag,
        },
        {
          to: `/increase/member`,
          label: `会员`,
          active_img: 'icon_zy_vip_active',
          default_img: 'icon_zy_vip',
          show: memberLevelModelFlag,
        },
        {
          to: `/increase/cardUser`,
          label: `我的`,
          active_img: 'icon_zy_my_active',
          default_img: 'icon_zy_my',
          show: this.isSpecialOrg(),
        },
      ];
    }
  },

  // mounted() {
  //   this.memberLevelModelFlag = JSON.parse(
  //     localStorage.getItem('userInfo')
  //   ).memberLevelModelFlag;
  // },
  methods: {
    onChange(index) {},
  },
};
</script>

<style lang="scss" scoped>
.t-orange {
  color: #f24a39;
}
.t-yellow {
  color: #ffae44;
}
.van-tabbar-item__icon .svg-icon {
  display: block;
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 4px;
  margin: 0 auto 4px;
}
</style>
