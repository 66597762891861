import request from '../request';

export function shop_order_list(params) {
  return request({
    url: `/shop/order/list`,
    method: `get`,
    params,
  });
}

// 分润收益 - 查询代理分润报表详情数据
export function report_benefit_info(params) {
  return request({
    url: `/report/benefit/info`,
    method: `get`,
    params,
  });
}

// 分润收益 - 查询每个产品下的分润金额
export function report_benefit_product_list(params) {
  return request({
    url: `/report/benefit/product/list`,
    method: `get`,
    params,
  });
}

// 分润收益 - 查询产品下的明细分润金额
export function report_benefit_product_detail(params) {
  return request({
    url: `/report/benefit/product/detail`,
    method: `get`,
    params,
  });
}

// 获取收益报表总收益
export function user_earnings_sum(params) {
  return request({
    url: `/user/earnings/sum`,
    method: `get`,
    params,
  });
}

// 获取收益汇总
export function user_earnings_info(params) {
  return request({
    url: `/user/earnings/info`,
    method: `get`,
    params,
  });
}

// 获取终端奖励列表
export function market_report_page(params) {
  return request({
    url: `/market/report/page`,
    method: `get`,
    params,
  });
}

// 获取终端奖励汇总数据
export function market_report_sum(params) {
  return request({
    url: `/market/report/sum`,
    method: `get`,
    params,
  });
}

// 获取收益报表-增值收益汇总
export function user_earnings_va_summary(params) {
  return request({
    url: `/user/earnings/va/summary`,
    method: `get`,
    params,
  });
}

// 获取收益报表-其他收益汇总
export function user_earnings_other_summary(params) {
  return request({
    url: `/user/earnings/other/summary`,
    method: `get`,
    params,
  });
}

// 获取收益报表-增值分页查询
export function user_earnings_page(params) {
  return request({
    url: `/user/earnings/page`,
    method: `get`,
    params,
  });
}
