export default [
  {
    path: '/ranking',
    name: 'ranking',
    redirect: '/ranking/index',
    component: {
      name: 'RankingWarp',
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'Ranking',
        component: () => import('@/views/ranking/index'),
      },{
        path: 'explain',
        name: 'Explain',
        component: () => import('@/views/ranking/explain'),
      },
    ],
  },
];
