const intoView = {
  inserted: function(el, binding, vnode) {
    const input = el.getElementsByTagName('input')[0];
    // scrollIntoView when element focus
    input.onfocus = () => {
      setTimeout(() => {
        input.scrollIntoView(); //  https://developer.mozilla.org/zh-CN/docs/Web/API/Element/scrollIntoView
        // console.log('scrollIntoView')
      }, 400);
    };
  },
  unbind: function(el, binding, vnode) {
    const input = el.getElementsByTagName('input')[0];
    input.onfocus = null;
    // console.log('touchDirective--unbind')
  }

};

export default intoView;