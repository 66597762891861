import request from '../request';

export function shop_order_list(params) {
  return request({
    url: `/shop/order/list`,
    method: `get`,
    params
  });
}

// 订单删除
export function shop_order_remove(id) {
  return request({
    url: `/shop/order/remove/${id}`,
    method: `post`,
  });
}

// 取消订单
export function shop_order_cancel(id) {
  return request({
    url: `/shop/order/cancel/${id}`,
    method: `post`,
  });
}

// 订单详情
export function shop_order_detail(id) {
  return request({
    url: `/shop/order/${id}`,
    method: `get`
  });
}

// SN详情（分页-终端订单）
export function shop_order_shop_sn_page(params) {
  return request({
    url: `/shop/order/shop/sn/page`,
    method: `get`,
    params
  });
}

// 获取提货码
export function shop_order_get_code(params) {
  return request({
    url: `/shop/order/get/code`,
    method: `get`,
    params
  });
}
