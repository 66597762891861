export default [
  {
    path: '/refuelPack',
    name: '加油包',
    redirect: '/refuelPack/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      // 加油包首页
      {
        path: 'index',
        name: 'refuelPackIndex',
        component: () => import('@/views/refuelPack'),
      },
      // 加油包详情
      {
        path: 'refuelPackDetail',
        name: 'refuelPackDetail',
        component: () => import('@/views/refuelPack/refuelPackDetail'),
      },
      // 加油包订单确认
      {
        path: 'refuelPackOrderConfirm',
        name: 'refuelPackOrderConfirm',
        component: () => import('@/views/refuelPack/order/orderConfirm'),
      },
      // 加油包订单结果
      {
        path: 'refuelPackOrderResult',
        name: 'refuelPackOrderResult',
        component: () => import('@/views/refuelPack/order/orderResult'),
      },
      // 加油包订单支付
      {
        path: 'refuelPackOrderCashier',
        name: 'refuelPackOrderCashier',
        component: () => import('@/views/refuelPack/order/orderCashier'),
      },
      // 加油包订单待支付
      {
        path: 'refuelPackOrderWaitPay',
        name: 'refuelPackOrderWaitPay',
        component: () => import('@/views/refuelPack/order/orderWaitPay'),
      },
      // 加油包订单待组合支付
      {
        path: 'refuelPackOrderCombination',
        name: 'refuelPackOrderCombination',
        component: () => import('@/views/refuelPack/order/orderCombination'),
      },
      // 加油包订单详情
      {
        path: 'refuelPackOrderDetail',
        name: 'refuelPackOrderDetail',
        component: () => import('@/views/refuelPack/order/orderDetail'),
      },
      // 我的加油包
      {
        path: 'myRefuelPack',
        name: 'myRefuelPack',
        component: () => import('@/views/refuelPack/myRefuelPack/index'),
      },
      // 我的加油包详情
      {
        path: 'myRefuelPackDetail',
        name: 'myRefuelPackDetail',
        component: () =>
          import('@/views/refuelPack/myRefuelPack/myRefuelPackDetail'),
      },
      // 我的-转让详情
      {
        path: 'transferRecordDetail',
        name: 'transferRecordDetail',
        component: () =>
          import('@/views/refuelPack/myRefuelPack/transferRecordDetail'),
      },
      // 转让权益
      {
        path: 'transferRefuelPack',
        name: 'transferRefuelPack',
        component: () =>
          import('@/views/refuelPack/myRefuelPack/transferRefuelPack'),
      },
      // 转让页
      {
        path: 'refuelPackMachineAssign',
        name: 'refuelPackMachineAssign',
        component: () => import('@/views/refuelPack/assign/index'),
      },
      // 订单列表
      {
        path: 'refuelPackOrder',
        name: 'refuelPackOrder',
        component: () => import('@/views/refuelPack/order/index'),
      },

      // 订单列表-sn记录
      {
        path: 'refuelPackOrderSnRecord/:id?',
        name: 'refuelPackOrderSnRecord',
        component: () => import('@/views/refuelPack/order/record'),
      },

      // 选择转让人
      {
        path: 'refuelPackChooseReceiver',
        name: 'refuelPackChooseReceiver',
        component: () => import('@/views/refuelPack/assign/chooseReceiver'),
      },
      // 转让结果
      {
        path: 'refuelPackTransferResult',
        name: 'refuelPackTransferResult',
        component: () => import('@/views/refuelPack/assign/result'),
      },
    ],
  },
];
