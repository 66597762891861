import axios from 'axios';
import store from '@/vuex/store.js';
import router from '../router';
import { Toast } from 'vant';
import NProgress from 'nprogress'; // Progress 进度条
NProgress.configure({ showSpinner: false });




const request = axios.create({
  baseURL: process.env.VUE_APP_OLD_API,
  // baseURL: 'http://juheba-gateway-test.juheba.top:8888/agent',
  timeout: 0, // 指定请求超时的毫秒数(0 表示无超时时间)
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': `application/json`,
    'X-Requested-With': `XMLHttpRequest`,
    'Cache-Control': `no-cache`,
    'Access-Control-Expose-Headers': 'authToken'
  },
  showError: true, // 显示错误消息
  showLoading: true, // 显示loading
  requireLogin: true, // 是否依赖登录
});

// 请求拦截
request.interceptors.request.use(
  function(config) {

    // let cancel;
    // 在发送请求之前做些什么
    store.commit(`SET_LOADING`, config.showLoading);
    NProgress.start();

    // config.cancelToken = new axios.CancelToken(function(c) {
    //   cancel = c;
    // });
    // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
    // stopRepeatRequest(reqList, config.url, cancel, `${config.url} 请求被中断`)

    // 如果有token,添加到请求报文 后台会根据该报文返回status
    // if(store.state.login.token) {
    //   config.headers.authToken = `${store.state.login.token}`;
    // }

    const userInfo = JSON.parse(localStorage.getItem(`userInfo`));
    if (userInfo) {
      config.headers.authToken = userInfo.authToken;
    }

    return config;
  },
  function(error) {
    // 对请求错误做些什么
    this.$toast(`网络错误,请稍后再试`);

    store.commit(`SET_LOADING`, false);
    NProgress.done();

    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function(response) {
    // allowRequest(reqList, response.config.url)
    // 对响应数据做点什么
    // 加到时器主要是为了 展示Loading效果 项目中应去除
    setTimeout(() => {
      store.commit(`SET_LOADING`, false);
      NProgress.done();
    }, 300);

    // if(response.data.data.authToken){
    //   store.commit('CHANGE_TOKEN', response.data.data.authToken);
    // }

    return response;
  },
  function(error) {
    // 对响应错误做点什么
    store.commit(`SET_LOADING`, false);
    NProgress.done();
    if (error.response) {
      const { status, data } = error.response;
      if (status == 401) {
        // 如果返回401 即没有权限，跳到登录页重新登录
        // store.commit('CHANGE_TOKEN',0);

        // Toast(data.message);
        router.replace({ path: `/login` });
        return;
      }

      Toast(data.message);
    }

    return Promise.reject(error);
  }
);
export default request;
