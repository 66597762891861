export default [
  {
    path: '/terminalOrder',
    redirect: '/terminalOrder/index',
    component: {
      render() {
        return (
          <keep-alive include={['TerminalOrder', 'TerminalOrderSort']}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index/:type?',
        name: 'TerminalOrder',
        component: () => import('@/views/terminalOrder'),
      },
      {
        path: 'sort/:type/:id',
        name: 'TerminalOrderSort',
        component: () => import('@/views/terminalOrder/sort'),
      },
      {
        path: 'snSearch/:id',
        name: 'SnSearch',
        component: () => import('@/views/terminalOrder/snSearch'),
        meta: {
          cnName: '选择sn',
        },
      },
      {
        path: 'boxSearch/:id',
        name: 'BoxSearch',
        component: () => import('@/views/terminalOrder/boxSearch'),
        meta: {
          cnName: '选择箱号',
        },
      },
      {
        path: 'express',
        name: 'TerminalOrderExpress',
        component: () => import('@/views/terminalOrder/express'),
        meta: {
          cnName: '填写物流信息',
        },
      },
      {
        path: 'sortFinish',
        name: 'SortFinish',
        component: () => import('@/views/terminalOrder/sortFinish'),
        meta: {
          cnName: '分拣/发货完成',
        },
      },
      {
        path: 'snRecord/:id',
        name: 'SnRecord',
        component: () => import('@/views/terminalOrder/snRecord'),
        meta: {
          cnName: 'SN记录',
        },
      },
      {
        path: 'verification/:id',
        name: 'Verification',
        component: () => import('@/views/terminalOrder/verification'),
        meta: {
          cnName: '核销确认',
        },
      },
      {
        path: 'verificationResult',
        name: 'VerificationResult',
        component: () => import('@/views/terminalOrder/verificationResult'),
        meta: {
          cnName: '核销成功',
        },
      },
      {
        path: 'expressInfo/:id',
        name: 'TerminalOrderExpressInfo',
        component: () => import('@/views/terminalOrder/expressInfo'),
        meta: {
          cnName: '查看物流信息',
        },
      },
    ],
  },
];
