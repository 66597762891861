import request from '../request';

// 榜单列表-分页
export function agent_market_incentive_list(params) {
  return request({
    url: `/market/incentive/list`,
    method: `get`,
    params
  });
}

// 榜单列表-检查是否开启激励榜单
export function agent_market_incentive_open(params) {
  return request({
    url: `/market/incentive/open`,
    method: `get`,
    params
  });
}

// 榜单列表-激励榜单排名
export function agent_market_incentive_rank(params) {
  return request({
    url: `/market/incentive/rank`,
    method: `get`,
    params
  });
}