// index.js

// 解决ios点击屏幕空白出不收起键盘
export function objBlurFun(sDom, time = 100) {
  let isIphone = navigator.userAgent.toUpperCase().includes('IPHONE'); // 判断ios环境
  if (isIphone) {
    let obj = document.querySelector(sDom); // 这里如果传入的是2个以上 需要用querySelectorAll() 同时
    objBlur(obj, time); // 需要遍历调用函数
  }
}
function objBlur(sDom, time = 300) {
  if (sDom) {
    sDom.addEventListener(
      'focus',
      () => {
        document.addEventListener('touchend', e => {
          // 这里的e非常重要，用来判断是否是点击了input表单
          docTouchend(time, sDom, e);
        });
      },
      false
    );
  }
}
// 元素失去焦点隐藏ihpone的软键盘
function docTouchend(time, sDom, e) {
  if (e.target != sDom) {
    // 如果点击的是屏幕空白处的情况下
    // console.log(sDom)
    setTimeout(() => {
      sDom.blur(); // 表单失去焦点键盘就会自动收起
      document.removeEventListener('touchend', docTouchend, false); // 收起之后移除监听器
    }, time);
  }
}
// 键盘收起end

export function flatten(arr) {
  let result = [];
  arr.forEach(item => {
    if (Array.isArray(item)) {
      result = result.concat(flatten(item));
    } else {
      result.push(item);
    }
  });
  return result;
}