import request from '../request';

// 机具管理-统计
export function device_sn_statistical() {
  return request({
    url: `/device/sn/statistical`,
    method: `get`
  });
}

// 机具转让列表
export function device_deliver_list(params) {
  return request({
    url: `/device/deliver/list`,
    method: `get`,
    params
  });
}

// 查询可转让机具
export function device_deliver_query(params) {
  return request({
    url: `/device/deliver/query`,
    method: `get`,
    params
  });
}

// 机具管理-代理授权的产品列表
export function device_sn_product_list() {
  return request({
    url: `/device/sn/product/list`,
    method: `get`
  });
}

// 新增机具转让订单
export function device_deliver_add(data) {
  return request({
    url: `/device/deliver/add`,
    method: `post`,
    data
  });
}

// 待接收订单数量
export function device_deliver_count_receive() {
  return request({
    url: `/device/deliver/count/receive`,
    method: `get`
  });
}

// 机具转让-接收列表
export function device_deliver_receiver_list(params) {
  return request({
    url: `/device/deliver/receiver/list`,
    method: `get`,
    params
  });
}

// 根据品牌id查询转让机具
export function device_deliver_byProductId(params) {
  return request({
    url: `/device/deliver/byProductId/${params.id}`,
    method: `get`,
    params
  });
}

// 机具管理列表
export function device_sn_list(params) {
  return request({
    url: `/device/sn/list`,
    method: `get`,
    params
  });
}

// 机具管理列表-详情
export function device_sn_detail(id) {
  return request({
    url: `/device/sn/detail/${id}`,
    method: `get`
  });
}
export function device_sn_detail_gt(params) {
  return request({
    url: `/device/getConfigMarket`,
    method: `get`,
    params
  });
}

// 拒绝或撤销订单
export function device_deliver_refused_or_undo(data) {
  return request({
    url: `/device/deliver/refused/or/undo`,
    method: `post`,
    data
  });
}

// 接收机具
export function device_deliver_receiver(id) {
  return request({
    url: `/device/deliver/receiver/${id}`,
    method: `post`
  });
}

// 检查转让单是否有考核过期的机具
export function device_deliver_check_examine(id) {
  return request({
    url: `/device/deliver/check/examine/${id}`,
    method: `get`,
  });
}

// ---------------------------------扣款记录------------------------------------
// 过期扣款列表
export function device_deduct_invalid_list(params) {
  return request({
    url: `/device/deduct/invalid/list`,
    method: `get`,
    params,
  });
}

// 过期扣款详情
export function device_deduct_invalid_detail(params) {
  return request({
    url: `/device/deduct/invalid/detail`,
    method: `get`,
    params,
  });
}

// 过期扣款列表统计
export function device_deduct_invalid_sum(params) {
  return request({
    url: `/device/deduct/invalid/sum`,
    method: `get`,
    params,
  });
}


// 伪激活列表
export function device_deduct_achieve_list(params) {
  return request({
    url: `/device/deduct/achieve/list`,
    method: `get`,
    params,
  });
}

// 伪激活详情
export function device_deduct_achieve_detail(params) {
  return request({
    url: `/device/deduct/achieve/detail`,
    method: `get`,
    params,
  });
}

// 伪激活列表统计
export function device_deduct_achieve_sum(params) {
  return request({
    url: `/device/deduct/achieve/sum`,
    method: `get`,
    params,
  });
}

// 机具转让-产品列表
export function device_deliver_agent_product_list(params) {
  return request({
    url: `/device/deliver/agent/product/list`,
    method: `get`,
    params,
  });
}

// 机具转让-箱号列表
export function device_deliver_sn_box_list(params) {
  return request({
    url: `/device/deliver/sn/box/list`,
    method: `get`,
    params,
  });
}

// 机具转让-号段选择-核查数量
export function device_deliver_sn_by_part(params) {
  return request({
    url: `/device/deliver/sn/by/part`,
    method: `get`,
    params,
  });
}

// 机具转让-SN列表(按箱)
export function device_deliver_sn_by_box(params) {
  return request({
    url: `/device/deliver/sn/by/box`,
    method: `get`,
    params,
  });
}

// 机具转让-转让(校验)
export function device_deliver_sn_verify(data) {
  return request({
    url: `/device/deliver/sn/verify`,
    method: `post`,
    data,
  });
}

// 机具转让-确认详情
export function device_deliver_sn_list_detail(data) {
  return request({
    url: `/device/deliver/sn/list/detail`,
    method: `post`,
    data,
  });
}

// 根据推荐码或手机号获取用户信息
export function device_deliver_search_agent(params) {
  return request({
    url: `/device/deliver/search/agent`,
    method: `get`,
    params
  });
}

// 获取活动方案筛选器
export function device_product_superior_market(params) {
  return request({
    url: `/device/product/superior/market/select`,
    method: `get`,
    params
  });
}
export function device_product_superior_market_gt(params) {
  return request({
    url: `/device/getPolicyList`,
    method: `get`,
    params
  });
}
export function device_product_superior__gt_rate(sn) {
  return request({
    url: `device/queryXyfRateAcitivity/${sn} `,
    method: `get`,
  });
}
export function device_product_superior_gt_llk(sn) {
  return request({
    url: `device/queryXyfViplist/${sn} `,
    method: `get`,
  });
}

// 设备SN配置活动
export function device_sn_superior_config(data) {
  return request({
    url: `/device/sn/superior/config/submit`,
    method: `post`,
    data
  });
}
export function device_sn_superior_xyf_config(data) {
  return request({
    url: `/device/submitXyf`,
    method: `post`,
    data
  });
}

//  查看产品活动状态
export function device_product_superior_status(params) {
  return request({
    url: `/device/product/superior/status`,
    method: `get`,
    params
  });
}


