import request from '../request';

//-----------------------------------积分商城---------------------------------------------

// 商品分类-列表
export function integral_wares_category(params) {
  return request({
    url: `/integral/wares/category`,
    method: `get`,
    params,
  });
}

// 商品详情富文本
export function integral_wares_context(id) {
  return request({
    url: `/integral/wares/context/${id}`,
    method: `get`,
  });
}

// 商品物流信息
export function integral_wares_logistic(id) {
  return request({
    url: `/integral/wares/logistic/${id}`,
    method: `get`,
  });
}

export const querySroeByH5 =(id)=>{
  return request({
    url:`/integral/order/payStatus/${id}`,
    method:"GET"
  })
}

// 商品-分页
export function integral_wares_page(params) {
  return request({
    url: `/integral/wares/page`,
    method: `get`,
    params,
  });
}

// 商品-SKU切换
export function integral_wares_sku(id) {
  return request({
    url: `/integral/wares/sku/${id}`,
    method: `get`,
  });
}

// 商品-详情
export function integral_wares_detail(id) {
  return request({
    url: `/integral/wares/${id}`,
    method: `get`,
  });
}

// 取消订单
export function integral_order_cancel(id) {
  return request({
    url: `/integral/order/cancel/${id}`,
    method: `post`,
  });
}

// 订单创建
export function integral_order_create(data) {
  return request({
    url: `/integral/order/create`,
    method: `post`,
    data,
  });
}

// 订单发货分页列表
export function integral_order_delivery_list(params) {
  return request({
    url: `/integral/order/delivery/list`,
    method: `get`,
    params,
  });
}

// 订单分页列表
export function integral_order_list(params) {
  return request({
    url: `/integral/order/list`,
    method: `get`,
    params,
  });
}

// 订单支付
export function integral_order_pay(data) {
  return request({
    url: `/integral/order/pay`,
    method: `post`,
    data,
  });
}

// 订单详情
export function integral_order_detail(id) {
  return request({
    url: `/integral/order/${id}`,
    method: `get`,
  });
}
//-----------------------------------个人积分---------------------------------------------
// app-个人积分查询
export function integral_user_app_info(params) {
  return request({
    url: `integral/user/app/info`,
    method: `get`,
    params,
  });
}

// app-个人积分查询
export function integral_user_app_sign_list(params) {
  return request({
    url: `/integral/user/app/sign/list`,
    method: `get`,
    params,
  });
}

// app-积分任务
export function integral_user_app_task(params) {
  return request({
    url: `/integral/user/app/task`,
    method: `get`,
    params,
  });
}

// app-签到
export function integral_user_app_sign(data) {
  return request({
    url: `/integral/user/app/sign`,
    method: `post`,
    data,
  });
}

// app-积分账单
export function integral_user_app_integral_flow(params) {
  return request({
    url: `/integral/user/app/integral/flow`,
    method: `get`,
    params,
  });
}

// app-分享海报
export function integral_user_app_post_share(data) {
  return request({
    url: `/integral/user/app/post/share`,
    method: `post`,
    data,
  });
}


//------------------------------积分营销活动--------------------------------
// 中奖名单
export function integral_activity_app_lucky_draw_roll(params) {
  return request({
    url: `/integral/activity/app/lucky/draw/roll`,
    method: `get`,
    params,
  });
}

// 幸运大抽奖活动开启状态查询
export function integral_activity_lucky_draw_exist(params) {
  return request({
    url: `/integral/activity/lucky/draw/exist`,
    method: `get`,
    params,
  });
}

// 幸运大抽奖
export function integral_activity_app_lucky_draw(agentId) {
  return request({
    url: `/integral/activity/app/lucky/draw/${agentId}`,
    method: `get`,
  });
}

// 幸运大抽奖-抽奖
export function integral_activity_app_lucky_draw_do(data) {
  return request({
    url: `/integral/activity/app/lucky/draw/do`,
    method: `post`,
    data,
  });
}


// 幸运大抽奖-我的奖励
export function integral_activity_app_lucky_draw_roll_my(params) {
  return request({
    url: `/integral/activity/app/lucky/draw/roll/my`,
    method: `get`,
    params,
  });
}

