<template>
  <div class="goods flex flex-middle-center" @click="onCallBack(data,data)">
    <van-image
      class="pic-goods"
      :src="curBuyPic || ''"
      alt=""
    />
    <div class="goods-info flex flex-justify-between">
      <div>
        <div class="flex flex-middle-center header">
          <div class="title omit">
            {{ title }}
          </div>
          <div
            v-if="data && data.waresType !== '200' && data.presellFlag === '1'"
            class="tag presale-tag"
          >
            预售
          </div>
          <div
            v-if="data && data.waresType === '200'"
            class="tag exchange-tag"
          >
            兑换
          </div>
        </div>
        <div class="options flex">
          <div
            v-for="(item, index) in options"
            :key="index"
            class="item"
          >
            {{ item.name }}：{{ item.value }}
          </div>
        </div>
      </div>

      <div class="flex flex-middle-center">
        <div class="money">
          ￥{{ price }}
        </div>
        <div class="num">
          x{{ count }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `GoodsCard`,
  props: {
    title: {
      type: String,
      default: ``,
    },
    curBuyPic: {
      type: String,
      default: ``,
    },
    options: {
      type: Array,
      default: () => [],
    },
    price: {
      type: [String, Number],
      default: ``,
    },
    count: {
      type: [String, Number],
      default: 0,
    },
    type: {
      type: String,
      default: ``,
    },
    tab: {
      type: String,
      default: `1`,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    //   console.log(this.data)

    
  },
  methods: {
    onCallBack(data,{waresId,waresType,price,waresName}){
      console.log(data)
      this.$emit('toGoodsCallback',{id:waresId,waresType,price, waresName })
      
    }
  },
};
</script>

<style lang="scss" scoped>
.goods {
  width: 320px;
  .pic-goods {
    width: 68px;
    height: 68px;
    flex-shrink: 0;
  }
  .goods-info {
    height: 68px;
    flex-direction: column;
    .header {
      width: 230px;
    }
    .title {
      width: 230px;
      font-size: 14px;
      color: #313235;
      margin-bottom: 4px;
    }
    .tag {
      padding: 2PX 0;
      width: 34px;
      height: auto;
      line-height: normal;
      text-align: center;
      font-size: 9PX;
    }
    .exchange-tag {
      background-color: #ecf3fe;
      color: #3d7bd8;
    }
    .presale-tag {
      background-color: #fee7d8;
      color: #cc6f28;
    }
    .options {
      .item {
        font-size: 12px;
        color: #93949d;
        margin-right: 16px;
      }
    }
    .money {
      font-size: 14px;
      color: #313235;
    }
    .num {
      font-size: 14px;
      color: #93949d;
    }
  }
}
</style>
