export default [
  {
    path: '/storehouse',
    redirect: '/storehouse/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      }
    },
    children: [
      {
        path: 'index',
        name: 'Storehouse',
        component: () => import('@/views/storehouse')
      },
      {
        path: 'detail/:id',
        name: 'StorehouseDetail',
        component: () => import('@/views/storehouse/detail')
      },
      {
        path: 'manager/:id/:name',
        name: 'StorehouseManager',
        component: () => import('@/views/storehouse/manager')
      },
      {
        path: 'addManager/:id',
        name: 'StorehouseAddManager',
        component: () => import('@/views/storehouse/addManager')
      },
      {
        path: 'add',
        name: 'AddStorehouse',
        component: () => import('@/views/storehouse/add')
      },
      {
        path: 'chooseAgent',
        name: 'ChooseAgent',
        component: () => import('@/views/storehouse/chooseAgent')
      }
    ]
  }
];
