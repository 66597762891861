import Vue from 'vue';
import Router from 'vue-router';

import transfer from './modules/transfer';
import storehouse from './modules/storehouse';
import user from './modules/user';
import login from './modules/login';
import verified from './modules/verified';
import shop from './modules/shop';
import message from './modules/message';
import order from './modules/order';
import terminalOrder from './modules/terminalOrder';
import income from './modules/income';
import business from './modules/business';
import machine from './modules/machine';
import training from './modules/training';
import increase from './modules/increase';
import newBusiness from './modules/newBusiness';
import merchant from './modules/merchant';
import newIncome from './modules/newIncome';
import ranking from './modules/ranking';
import refuelPack from './modules/refuelPack';
import SIMcard from './modules/SIMcard'

Vue.use(Router);


// 获取原型对象上的push函数
const originalPush = Router.prototype.push;
// 修改原型对象中的push方法
const originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)

}

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      redirect: '/home/index',
      component: {
        name: 'HomeWrapper',
        render() {
          return (
            <keep-alive include={this.$store.state.viewsCache.caches}>
              <router-view />
            </keep-alive>
          );
        },
      },
      children: [
        {
          path: 'index',
          name: 'home',
          component: () => import('@/views/home/index'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'pageDetail',
          name: 'pageDetail',
          component: () => import('@/views/home/pageDetail'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },

    ...login,
    ...verified,
    ...shop,
    ...message,
    ...order,
    ...terminalOrder,
    ...income,
    ...business,
    ...machine,
    ...transfer,
    ...storehouse,
    ...user,
    ...training,
    ...increase,
    ...newBusiness,
    ...merchant,
    ...newIncome,
    ...ranking,
    ...refuelPack,
    ...SIMcard,
  ],
  scrollBehavior(to, from, savedPosition) {
    // scrollBehavior(to, from, savedPosition) {
    // console.log('跳转数据',to, from, savedPosition);
    if (savedPosition) return savedPosition;
    return {
      x: 0,
      y: 0,
    };
  },
});
