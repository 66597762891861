export const storage = {
  /**
   * @description 通过键获取值
   * @param {String} key 键
   * @return {String} 返回该键所对应的值
   */
  getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  },
  /**
   * @description 设置值
   * @param {String} key 键
   * @param {String} value 对该键设置值
   */
  setItem(key, value) {
    value && localStorage.setItem(key, JSON.stringify(value));
  },
  /**
   * @description 通过键获取值
   * @param {String} key 键
   * @return {String} 返回该键所对应的值
   */
  removeItem(key) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  }
};

export const session = {
  /**
   * @description 通过键获取值
   * @param {String} key 键
   * @return {String} 返回该键所对应的值
   */
  getItem(key) {
    return JSON.parse(sessionStorage.getItem(key));
  },
  /**
   * @description 设置值
   * @param {String} key 键
   * @param {String} value 对该键设置值
   */
  setItem(key, value) {
    value && sessionStorage.setItem(key, JSON.stringify(value));
  },
  /**
   * @description 通过键获取值
   * @param {String} key 键
   * @return {String} 返回该键所对应的值
   */
  removeItem(key) {
    sessionStorage.removeItem(key);
  },
  clear() {
    sessionStorage.clear();
  }
};

export const cookie = {
  /**
   * @description 通过键获取值
   * @param {String} cname 名称
   * @return {String} 返回该键所对应的值
   */
  getItem(cname) {
    if (document.cookie.length > 0) {
      let cstart = document.cookie.indexOf(cname + '=');
      if (cstart !== -1) {
        cstart = cstart + cname.length + 1;
        let cend = document.cookie.indexOf(';', cstart);
        if (cend === -1) {
          cend = document.cookie.length;
        }
        return unescape(document.cookie.substring(cstart, cend));
      }
    }
    return '';
  },
  /**
   * @description 设置值
   * @param {String} cname 名称
   * @param {String} value 设置的值
   * @param {Number} expires 设置有效期分为单位
   */
  setItem(cname, value, expires) {
    let date = new Date();
    date.setTime(date.getTime() + expires * 60 * 1000);
    document.cookie = cname + '=' + escape(value) + ';expires=' + date.toUTCString();
  },
  /**
   * @description 通过键获取值
   * @param {String} cname 名称
   */
  removeItem(cname) {
    cookie.setCookie(cname, "", -1);
  },
};

