import request from '../request_old';

// 获取1.0服务费
export function divide_queryService(params) {
  return request({
    url: `/divide/queryService`,
    method: `get`,
    params
  });
}

// 获取1.0服务费详情
export function divide_queryServiceMx(params) {
  return request({
    url: `/divide/queryServiceMx`,
    method: `get`,
    params
  });
}




