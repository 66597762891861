export default [
  {
    path: '/transfer',
    redirect: '/transfer/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'Transfer',
        component: () => import('@/views/transfer'),
      },
      {
        path: 'unRealted',
        name: 'UnRealted',
        component: () => import('@/views/transfer/unRealted'),
      },
      {
        path: 'detail',
        name: 'TransferDetail',
        component: () => import('@/views/transfer/detail'),
      },
      {
        path: 'handleTransfer',
        name: 'HandleTransfer',
        component: () => import('@/views/transfer/handleTransfer'),
      },
      {
        path: 'handleTransferOut',
        name: 'HandleTransferOut',
        component: () => import('@/views/transfer/handleTransferOut'),
      },
      {
        path: 'inventory',
        name: 'Inventory',
        component: () => import('@/views/transfer/inventory'),
      },
      {
        path: 'inventoryDetail',
        name: 'InventoryDetail',
        component: () => import('@/views/transfer/inventoryDetail'),
      },
      {
        path: 'factory/:type/:depotType',
        name: 'TransferFactory',
        component: () => import('@/views/transfer/factory'),
      },
      {
        path: 'snRecord/:id',
        name: 'TransferSnRecord',
        component: () => import('@/views/transfer/snRecord'),
      },
      {
        path: 'result/:type',
        name: 'TransferResult',
        component: () => import('@/views/transfer/result'),
      },
      {
        path: 'deductionRecord',
        name: 'DeductionRecord',
        component: () => import('@/views/transfer/deductionRecord/index'),
      },
      {
        path: 'deductionInfo',
        name: 'DeductionInfo',
        component: () => import('@/views/transfer/deductionRecord/detail'),
      }
    ],
  },
];
