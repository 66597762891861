import request from '../request';

// 获取腾讯云sign
export function ocr_sdk_args(params) {
  return request({
    url: `/user/realname/ocr/sdk_args`,
    method: `get`,
    params
  });
}

// 银行卡bin
export function bankcard_bin(params) {
  return request({
    url: `/user/realname/bank/card/bin`,
    method: `get`,
    params
  });
}

// 实名认证
export function realname_cert(data) {
  return request({
    url: `/user/realname/cert`,
    method: `post`,
    data
  });
}

// 支行列表
export function zBank_list(params) {
  return request({
    url: `/user/realname/get/zBank`,
    method: `get`,
    params
  });
}

// 获取顶级代理信息
export function getTopAgent(params) {
  return request({
    url: `/user/agent/getTopIdCard`,
    method: `get`,
    params
  });
}

//h5传身份证的接口
export function id_card_server(data) {
  return request({
    url: `/user/realname/id-card-server`,
    method: `post`,
    data
  });
}

//h5传银行卡的接口
export function bank_card_server(data) {
  return request({
    url: `/user/realname/bank-card-server`,
    method: `post`,
    data
  });
}









