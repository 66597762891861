import request from '../request';

// 加油包-查询生效在的加油包活动
export function agent_market_refuel_valid_activity(params) {
  return request({
    url: `/market/refuel/valid/activity`,
    method: `get`,
    params,
  });
}

// 加油包-查询加油包列表
export function agent_market_refuel_pack_list(id) {
  return request({
    url: `/market/refuel/pack/list/${id}`,
    method: `get`,
  });
}

// 加油包-查询当前用户加油包权益
export function agent_market_refuel_my_rights(id) {
  return request({
    url: `/market/refuel/my/rights/${id}`,
    method: `get`,
  });
}

// 加油包-查询当前加油包详情
export function agent_market_refuel_pack_detail(id) {
  return request({
    url: `/market/refuel/pack/detail/${id}`,
    method: `get`,
  });
}

// 加油包-详情-（转让,接收订单中用）
export function market_refuel_pack_detail_for_deliver(id) {
  return request({
    url: `/market/refuel/pack/detail/for/deliver/${id}`,
    method: `get`,
  });
}

// 加油包-创建加油包订单
export function market_refuel_create(data) {
  return request({
    url: `/market/refuel/order/create`,
    method: `post`,
    data,
  });
}

// 加油包-查询当前加油包订单详情
export function market_refuel_detail(id) {
  return request({
    url: `/market/refuel/order/detail/${id}`,
    method: `get`,
  });
}

// 加油包-获取用户加油包订单
export function agent_market_refuel_order_page(params) {
  return request({
    url: `/market/refuel/order/page`,
    method: `get`,
    params,
  });
}

// 加油包-订单支付
export function market_refuel_order_pay(data) {
  return request({
    url: `/market/refuel/order/pay`,
    method: `post`,
    data,
  });
}

export const queryRefuelByH5 = (id) => {
  return request({
    url: `/market/refuel/order/payStatus/${id}`,
    method: "GET"
  })
}

// 加油包-删除订单
export function market_refuel_order_delete(data) {
  return request({
    url: `/market/refuel/order/delete`,
    method: `post`,
    data,
  });
}

// 加油包-取消订单
export function market_refuel_order_cancel(data) {
  return request({
    url: `/market/refuel/order/cancel`,
    method: `post`,
    data,
  });
}

// 加油包-上传凭证
export function market_refuel_order_upload_voucher(data) {
  return request({
    url: `/market/refuel/order/upload/voucher`,
    method: `post`,
    data,
  });
}

// 加油包-查询物流信息
export function market_refuel_order_logistics(id) {
  return request({
    url: `/market/refuel/order/logistics/${id}`,
    method: `get`,
  });
}

// ---------------------------------转让------------------------------------
// 我的加油包
export function market_refuel_agent_pack_list(params) {
  return request({
    url: `/market/refuel/agent/pack/list`,
    method: `get`,
    params,
  });
}

// 我的加油包-详情
export function market_refuel_agent_pack_detail(id) {
  return request({
    url: `/market/refuel/agent/pack/detail/${id}`,
    method: `get`,
  });
}
