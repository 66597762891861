// 一进来先获取包名确认怎么跳转

import Vue from 'vue';
import Vuex from 'vuex';
import 'amfe-flexible';
import App from './App.vue';
import router from './router';
import store from '@/vuex/store.js'; // vuex
import JSEncrypt from 'jsencrypt';
import * as api from './http';
api.default.install(Vue);

import Vant from 'vant';
import 'vant/lib/index.less';
import '@/styles/index.scss';
import '@/styles/quill.snow.css';

import moment from 'moment';
import '@/utils/number';

import Navbar from '@/components/navbar.vue';
import TabBar from '@/components/tabbar.vue';
import TrainingTabbar from '@/components/trainingTabbar.vue';
import CardLoanTabbar from '@/components/cardLoanTabbar.vue';
import Prompt from '@/components/prompt.vue';
import GoodsCard from '@/components/goodsCard.vue';
import Smsbutton from '@/components/smsbutton.vue';
import NoData from '@/components/noData.vue';
import ConfirmPod from '@/components/confirmPod.vue';

import commonMixin from '@/mixins/common';
import Clipboard from './directive/clipboard';

import promptPlugin from './plugins/prompt';
import debounceDirective from '@/directive/debounce';

import 'nprogress/nprogress.css'; // Progress 进度条样式
import '@/icons'; // icon

const F2 = require('@antv/f2');
import '@vant/touch-emulator';

Vue.prototype.$F2 = F2;
// 引入所有的交互行为
// require('@antv/f2/lib/interaction/');
import 'swiper/css/swiper.css';

Vue.component(`Navbar`, Navbar);
Vue.component(`TabBar`, TabBar);
Vue.component(`TrainingTabbar`, TrainingTabbar);
Vue.component(`CardLoanTabbar`, CardLoanTabbar);
Vue.component(`Prompt`, Prompt);
Vue.component(`GoodsCard`, GoodsCard);
Vue.component(`Smsbutton`, Smsbutton);
Vue.component(`NoData`, NoData);
Vue.component(`ConfirmPod`, ConfirmPod);

// 瀑布流布局 - 全局注册
import waterfall from 'vue-waterfall2';
Vue.use(waterfall);
Vue.use(Vant);
Vue.use(Vuex);
// Vue.use(Lazyload);
Vue.use(debounceDirective);

Vue.prototype.$moment = moment;
Vue.use(promptPlugin);
Vue.config.productionTip = false;
Vue.config.devtools = true;
// 用钩子函数beforeEach()对路由进行判断

const VUE_APP_CONFIG_RSA_PUBKEY = process.env.VUE_APP_CONFIG_RSA_PUBKEY;

// 引入自定义touch指令 测试intoView
import inputIntoViewDirective from '@/directive/intoView';
Vue.directive('intoView', inputIntoViewDirective);

// 加密
Vue.prototype.$encrypt = function (str) {
  const encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(VUE_APP_CONFIG_RSA_PUBKEY); // 设置 加密公钥
  const data = encryptStr.encrypt(str.toString()); // 进行加密
  return data;
};
// 解密
Vue.prototype.$decrypt = function (str) {
  const decryptStr = new JSEncrypt();
  decryptStr.setPublicKey(VUE_APP_CONFIG_RSA_PUBKEY); // 设置 加密公钥
  const data = decryptStr.decrypt(str.toString()); // 进行加密
  return data;
};

router.beforeEach((to, from, next) => {
  const orgList = ['1539501826605514753', '1582291729177579522', '1593547403773566977', '1635223162891988993'];
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || '';
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', '/' + to.fullPath]);
    }
  }
  if (to.meta.requireAuth) {
    console.log('requireAuth')
    // 需要权限,进一步进行判断
    if (userInfo) {
      // 如果已经登录并且账号是在此ID下则处理
      if (orgList.includes(userInfo.orgId)) {
        if (to.name === 'home') {
          next({ path: `/increase` });
        }
        next();
      }
      next();
    } else {
      // 如果没有权限,重定向到登录页,进行登录
      next({
        path: '/login',
        query: { packageName: to.query.packageName },
      });
    }
  } else {
    console.log('notrequireAuth')
    if (userInfo) {
      if (orgList.includes(userInfo.orgId)) {
        if (to.name === 'home') {
          next({ path: `/increase` });
        }
        next();
      }
    }
    next();
  }
});

Vue.mixin(commonMixin);
Vue.use(Clipboard);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

// 中控易动
Vue.prototype.$statusBarHeight = 0;
document.documentElement.style.setProperty(`--status-bar-height`, `0.01px`);
document.addEventListener(`deviceready`, () => {
  navigator.mobileCenter.getStatusBarHeight(res => {
    // getStatusBarHeight返回的数值在IOS和安卓中各不相同
    // IOS返回的是逻辑像素，而安卓中是物理像素，需要除以设备像素比
    const height = device.platform === `iOS` ? res : res / devicePixelRatio;
    document.documentElement.style.setProperty(`--status-bar-height`, `${height}px`);
    Vue.prototype.$statusBarHeight = height;
  });

  // 保证一个运行周期内”更新版本“弹窗只提示一次
  localStorage.removeItem(`firstLoad`);

  // 获取app信息
  Vue.prototype.$appName = ``;
  navigator.appInfo.getInfo(
    result => {
      console.log(`%c 🍐 result: `, `font-size:20px;background-color: #7F2B82;color:#fff;`, JSON.stringify(result));
      Vue.prototype.$appName = result.name;
      // Vue.prototype.$packageName = 'com.rhb.app';
      Vue.prototype.$packageName = result.id;
      Vue.prototype.$appVersion = result.version;
      window.apppackageName = result.id;

      // isGoBack是否显示返回导航条
      const options = {
        url: 'https://crmeb1.juheba.com/static/html/pc.html',
        title: '',
        isGoBack: false,
        clearHistory: true,
      };
      const web = navigator.WebPlugin || navigator.Web;
      if (result.id === 'com.duolaimi4.app' && device.platform === `iOS`) {
        web.openWeb(
          result => {},
          error => {
            console.log('err==>', error);
          },
          options
        );
        return false;
      }
      // const currentVersion = localStorage.getItem('currentVersion')
      // !currentVersion && localStorage.setItem('currentVersion', result.version)
    },
    error => {
      console.log(`err==>`, error);
    }
  );

  // 清除浏览器缓存
  if (navigator.webView) {
    navigator.webView.clearCache(1 << 0);
  }

  var model = device.model;
  var platform = device.platform;
  var version = device.version;
  var uuid = device.uuid;
  var manufacturer = device.manufacturer;
  var serial = device.serial;
  var macAddress = device.macAddress;
  const deviceInfo = {
    macAddress: macAddress,
    manufacturer: manufacturer,
    model: model,
    osType: platform,
    osVersion: version,
    serial: serial,
    uuid: uuid,
  };
  Vue.prototype.$deviceInfo = deviceInfo;
});

// 容联客服
// const script = document.createElement(`script`);
// script.src =
//   `https://webchat.7moor.com/javascripts/7moorInit.js?accessId=e0567ce0-356b-11ec-9868-614d132504b5&autoShow=false&language=ZHCN`;
// script.async = `async`;
// document.head.append(script);
