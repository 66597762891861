<template>
  <div>
    <!-- <div class="lottie">
      <lottie :options="defaultOptions" :height="30" :width="50" class="dot" />
    </div> -->
    <div
      class="shade"
      :class="{ hide: ignore }"
    />
  </div>
</template>

<script>
// import lottie from 'vue-lottie';
import animationData from './loading.json';
export default {
  components: {
    // lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData, loop: true },
      animationSpeed: 1,
      anim: {},
    };
  },
  computed: {
    ignore() {
      return this.$route.name === `performanceDetails`;
    },
  },
};
</script>

<style lang="scss" scoped>
.lottie {
  width: 50px;
  height: 30px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba($color: #000000, $alpha: 0.7);
  border-radius: 14px;
  z-index: 99999;
}
.dot {
  transform: scale(1.6);
}
.shade {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}
.hide {
  display: none;
}
</style>
