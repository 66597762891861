import * as types from '../types';
import { storage } from '@/utils/storage';
import { $api } from '@/http';
const state = {
  fetchLoading: false, //全局加载状态的Loading
  unReadMessageNum: '',
  integralNum: 0,
  avatarUrl: null,
};

const mutations = {
  // loading开关
  [types.SET_LOADING](state, res) {
    state.fetchLoading = res;
  },
  // 设置未读
  SET_UNREAD_MESSAGE_NUM(state, res) {
    state.unReadMessageNum = res;
  },
  // 设置积分数
  SET_INTEGRAL_NUM(state, res) {
    state.integralNum = res;
  },
  // 设置头像
  SET_AVATAR_URL(state, res) {
    state.avatarUrl = res;
  },
};

const actions = {
  async getAvatar({ commit, state }) {
    const res = await $api.user.user_agent_head_portrait_get();
    const { data } = res.data;
    commit(`SET_AVATAR_URL`, data);
  },

  async getIntegralNum({ commit, state }) {
    const res = await $api.integral.integral_user_app_info();
    const { data } = res.data;
    commit(`SET_INTEGRAL_NUM`, data.value);
  },

  async posterShare({ commit, state }, data) {
    const res = await $api.integral.integral_user_app_post_share(data);
    //const { data } = res.data;
    //commit(`SET_INTEGRAL_NUM`, data.value);
  },
};

const getters = {
  unReadMessageNum: state => state.unReadMessageNum,
  integralNum: state => state.integralNum,
  avatarUrl: state => state.avatarUrl,
};

export default {
  //namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
