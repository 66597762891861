import request from '../request';

// 获取文件访问地址
export function common_file_url(params) {
  return request({
    url: `/common/file/url`,
    method: `get`,
    params
  });
}

// 地区查询
export function common_area_query(params) {
  return request({
    url: `/common/area/query`,
    method: `get`,
    params
  });
}

// 字典
export function common_dict(code) {
  return request({
    url: `/common/dict/${code}`,
    method: `get`
  });
}


// 品牌列表
export function common_brand_list(params) {
  return request({
    url: `/common/brand/list`,
    method: `get`,
    params,
  });
}

// 产品列表
export function common_product_list(params) {
  return request({
    url: `/common/product/list`,
    method: `get`,
    params,
  });
}

// 型号列表
export function common_model_list(params) {
  return request({
    url: `/common/model/list`,
    method: `get`,
    params,
  });
}

// 本人仓库
export function common_depot_list(params) {
  return request({
    url: `/common/depot/list`,
    method: `get`,
    params,
  });
}

// 仓库列表（负责人|仓管）
export function common_charge_depot_list(params) {
  return request({
    url: `/common/charge/depot/list`,
    method: `get`,
    params
  });
}
