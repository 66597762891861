var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-tabbar',{attrs:{"active-color":"#FF945F","inactive-color":"#93949D","route":"","z-index":"999"},on:{"change":_vm.onChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"training","replace":"","to":"/training/index"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('svg-icon',{attrs:{"icon-class":props.active
                ? 'icon_ranking_active'
                : 'icon_ranking_default'}})]}}])},[_c('span',[_vm._v("营训排行")])]),(_vm.agentRole.includes(500) || _vm.agentRole.includes(100))?_c('van-tabbar-item',{attrs:{"name":"initiate","to":"/training/initiate"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('svg-icon',{attrs:{"icon-class":props.active
                ? 'icon_dev_active'
                : 'icon_initiate_default'}})]}}],null,false,2738781499)},[_c('span',[_vm._v("发起训练营")])]):_vm._e(),_c('van-tabbar-item',{attrs:{"name":"mycamp","to":"/training/mycamp"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('svg-icon',{attrs:{"icon-class":props.active
                ? 'icon_mycamp_active'
                : 'icon_mycamp_default'}})]}}])},[_c('span',[_vm._v("我的营训")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }