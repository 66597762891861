export default [
  {
    path: '/SIMcard',
    // name: 'SIMcard',
    redirect: '/SIMcard/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'SIMcard',
        component: () => import('@/views/SIMcard'),
      },
      {
        path: 'transaction',
        name: 'transaction',
        component: () => import('@/views/SIMcard/transaction'),
      },
      {
        path: 'succeed',
        name: 'succeed',
        component: () => import('@/views/SIMcard/succeed'),
      },
      {
        path: 'orderInquiry',
        name: 'orderInquiry',
        component: () => import('@/views/SIMcard/orderInquiry'),
      },
      {
        path: 'promotionHB',
        name: 'promotionHB',
        component: () => import('@/views/SIMcard/promotionHB'),
      },
      {
        path: 'cardparticulars',
        name: 'cardparticulars',
        component: () => import('@/views/SIMcard/cardparticulars'),
      },
      {
        path: 'cardOrder',
        name: 'SIMcardCardOrder',
        component: () => import('@/views/SIMcard/cardOrder'),
      },
      {
        path: 'brokeragePage',
        name: 'brokeragePage',
        component: () => import('@/views/SIMcard/brokeragePage'),
      },
      {
        path: 'share',
        // name: 'SIMcardShare',
        component: () => import('@/views/SIMcard/share'),
      },
      {
        path: 'myCardData',
        name: 'myCardData',
        component: () => import('@/views/SIMcard/myCardData'),
      },
    ],
  },
];
