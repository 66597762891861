var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-nav-bar',_vm._g(_vm._b({staticClass:"navbar",class:_vm.classObj[_vm.type],style:({
        backgroundColor: _vm.bgColor,
        color: _vm.whiteArrowArr.includes(_vm.type)
          ? '#fff !important'
          : '#000 !important',
      }),attrs:{"title":_vm.title,"border":false,"fixed":true,"left-text":"","right-text":"","safe-area-inset-top":true},on:{"click-left":_vm.onClickLeft},scopedSlots:_vm._u([(_vm.hasBack)?{key:"left",fn:function(){return [_vm._t("left",function(){return [(_vm.blackArrowArr.includes(_vm.type))?_c('svg-icon',{staticClass:"arrow",attrs:{"icon-class":"icon_arrow_left_black"}}):_vm._e(),(_vm.whiteArrowArr.includes(_vm.type))?_c('svg-icon',{staticClass:"arrow",attrs:{"icon-class":"icon_arrow_left_white"}}):_vm._e()]})]},proxy:true}:null,{key:"title",fn:function(){return [_vm._t("title",function(){return [_c('span',{style:({
              color: _vm.whiteArrowArr.includes(_vm.type)
                ? '#fff !important'
                : '#000 !important',
            })},[_vm._v(" "+_vm._s(_vm.title)+" ")])]})]},proxy:true},{key:"right",fn:function(){return [_vm._t("right")]},proxy:true}],null,true)},'van-nav-bar',_vm.$attrs,false),_vm.$listeners)),(_vm.hasPlaceholder)?_c('div',{staticClass:"placeholder"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }