export default [
  {
    path: '/merchant',
    name: 'merchant',
    redirect: '/merchant/index',
    component: {
      name:'merchantWrapper',
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        )
      },
    },
    children: [
      {
        path: 'index',
        name: 'merchant',
        component: () => import('@/views/merchant/index'),
      },
    ]
  },
]
