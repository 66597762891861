<template>
  <div>
    <van-tabbar
      v-model="active"
      active-color="#FF945F"
      inactive-color="#93949D"
      route
      z-index="999"
      @change="onChange"
    >
      <van-tabbar-item
        name="training"
        replace
        to="/training/index"
      >
        <template #icon="props">
          <svg-icon
            :icon-class="
              props.active
                ? 'icon_ranking_active'
                : 'icon_ranking_default'
            "
          />
        </template>
        <span>营训排行</span>
      </van-tabbar-item>
      <van-tabbar-item
        v-if="agentRole.includes(500) || agentRole.includes(100)"
        name="initiate"
        to="/training/initiate"
      >
        <template #icon="props">
          <svg-icon
            :icon-class="
              props.active
                ? 'icon_dev_active'
                : 'icon_initiate_default'
            "
          />
        </template>
        <span>发起训练营</span>
      </van-tabbar-item>
      <van-tabbar-item
        name="mycamp"
        to="/training/mycamp"
      >
        <template #icon="props">
          <svg-icon
            :icon-class="
              props.active
                ? 'icon_mycamp_active'
                : 'icon_mycamp_default'
            "
          />
        </template>
        <span>我的营训</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: `TabBar`,
  props: {
    agentRole: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: ``
    };
  },
  methods: {
    onChange() {},
  },
};
</script>

<style lang="scss" scoped>
.van-tabbar-item__icon .svg-icon {
     display: block;
     width: 20PX !important;
     height: 20PX !important;
     margin:0 auto 4PX;
   }
</style>
